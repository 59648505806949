/** @jsxImportSource theme-ui */

import { useState, useEffect } from "react";
import {
  Flex,
  Box,
  Label,
  Text,
  Alert,
  Input,
  Button,
  Heading,
  Card,
  Close,
} from "theme-ui";
import { ErrorIcon } from "@brinks/common/Icons/Components/error";
import { useTranslation } from "react-i18next";

import useTheme from "../../Hooks/useTheme";
import Logo from "@brinks/common/Icons/Logo";
import { Api } from "@brinks/common/api/auth";
import SuccessPage from "./SuccessPage";
import LeftSidePage from "./LeftSidePage";
import LanguageSelector from "./LanguageSelector";
import TagManager from "react-gtm-module";
import { useSearchParams, useLocation } from "react-router-dom";
import { REACT_APP_NATIVE_APP_BASE_URL } from "@brinks/common/utils/Config";
import { sendOtp } from "@brinks/common/reducers/authSlice";
import ResetPasswordOtp from "./ResetPasswordOtp";

interface StateProps {
  min8Char: boolean;
  upperLowerFreq: boolean;
  mixLetterNumber: boolean;
  atlestOneSpecialChar: boolean;
  unmatch: boolean;
}
function SetPassword() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isTokenError, setIsTokenError] = useState(false);
  const [inResetFlow, setInResetFlow] = useState(false);
  const location = useLocation();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        Page_title: "User registration | Choose password",
      }
    });
  }, []);

  useEffect(() => {
    const obj = {
      recoveryToken: searchParams.get("code"),
    };
    async function fetchToken() {
      try {
        const url: any = `${REACT_APP_NATIVE_APP_BASE_URL}/api/v1/authn/recovery/token`;
        const res = await fetch(url, {
          method: "POST",
          body: JSON.stringify(obj),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        const resp = await res.json();
        if (resp?.errorId) {
          setIsTokenError(true);
          return
        }
        setStateTokenResp(resp)

      } catch (error: any) {
        setIsTokenError(true);
      }
    }
    if (searchParams.get("code")) {
      setInResetFlow(true)
      fetchToken();
    }
  }, []);

  const [password, setPassword] = useState("");
  const [matchPassword, setMatchPassword] = useState("");
  const [showSuccessPage, setShowSuccessPage] = useState(false);
  const [showResetOtp, setShowResetOtp] = useState(false)
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stateTokenResp, setStateTokenResp] = useState<any>('')
  const { t } = useTranslation();
  const [state, setState] = useState<StateProps>({
    min8Char: false,
    upperLowerFreq: false,
    mixLetterNumber: false,
    atlestOneSpecialChar: false,
    unmatch: false,
  });
  const themeType = useTheme();

  const handleSubmit = (e: any) => {
    const newState = { ...state };
    const UpperlowerCaseLetters = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])/);
    const numbers = new RegExp(/(?=.*[0-9])/);
    const specialChar = new RegExp(
      /(?=.*[!@#$%^&*])/
    );
    if (password !== matchPassword) {
      newState.unmatch = true;
    } else {
      newState.unmatch = false;
    }
    if (!UpperlowerCaseLetters.test(password)) {
      newState.upperLowerFreq = true;
    } else {
      newState.upperLowerFreq = false;
    }

    if (!numbers.test(password)) {
      newState.mixLetterNumber = true;
    } else {
      newState.mixLetterNumber = false;
    }
    if (!specialChar.test(password)) {
      newState.atlestOneSpecialChar = true;
    } else {
      newState.atlestOneSpecialChar = false;
    }

    if (password.length < 8) {
      newState.min8Char = true;
    } else {
      newState.min8Char = false;
    }
    setState(newState);
    const areTrue = Object.values(newState).every(
      value => value === false
    );
    if (areTrue) {
      if(searchParams.get("code")){
        handleReset()
      }else{
        handleSetPassword();
      }
     
    } else {
      return;
    }
    e.preventDefault();
  };

  const handleSetPassword = async () => {
    const paylaod: any = {
      userId: localStorage.getItem("userId"),
      password: password,
    };
    setLoading(true);
    try {
      const res = await new Api().auth.setPassword(paylaod);
      setLoading(false);
      setShowSuccessPage(true);
    } catch (error: any) {
      setIsError(true);
      setLoading(false);
      if (!error?.response) {
        throw error
      }

    }
  };


  const handleReset = async () => {
    setLoading(true);
    try {
      const url: any = `${REACT_APP_NATIVE_APP_BASE_URL}/api/v1/authn/credentials/reset_password`;
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          stateToken: stateTokenResp?.stateToken,
          newPassword: password
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const resp = await res.json();
      if (resp?.errorId) {
        setIsTokenError(true);
        return
      }
      localStorage.setItem('stateToken',JSON.stringify(resp))
      setStateTokenResp(resp)
      setLoading(false);
      const sendOtpResp = resp?.status === "MFA_REQUIRED" && await sendOtp(resp);
      setShowResetOtp(true)
    } catch (error: any) {
      setIsTokenError(true);
      setLoading(false);
    }
  }

  return (
    <Flex
      data-testid="SetPassword-wrapper"
      sx={{
        alignItems: "center",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <LeftSidePage />
      <Flex
        data-testid="SetPassword-wrapper-lang"
        bg={"white"}
        sx={{
          width: ["100%", "60%"],
          height: "100vh",
          alignItems: "center",
          flexDirection: "column",
          margin: "0 auto",
          overflowY: "scroll"
        }}
      >

        <Flex
          data-testid="SetPassword-wrapper-lang-text"
          sx={{ alignSelf: "flex-end" }}
          mr={31}
          mt={[10, 30]}
        >
          <LanguageSelector />

        </Flex>

        <Flex sx={{ display: ["block", "none"], mt: [10, 50] }}>
          <Logo themeType={themeType} />
        </Flex>
        {isTokenError ? (
          <Alert
            data-testid="activation-error-alert"
            sx={{ mt: [70, 0], mx: [16, 0], }}
          >
            {t("Registration.expired_msg")}
            <Close
              ml="auto"
              mr={-2}
              onClick={() => setIsTokenError(false)}
              data-testid="activation-error-alert-close-icon"
            />
          </Alert>
        ) : null}
        {isError ? (
          <Alert
            data-testid="SetPassword-error-alert"
            sx={{ mt: [50, 0], mx: [16, 0] }}
          >
            {t("Registration.password_error")}
            {
              TagManager.dataLayer({
                dataLayer: {
                  event: "Click_event",
                  Page_title: "User registration | Choose password",
                  Action: "Error message: not valid or not the same password"
                }
              })
            }
            <Close
              data-testid="setPassword-close-icon"
              ml="auto"
              mr={-2}
              onClick={() => setIsError(false)}
            />
          </Alert>
        ) : null}
        {showSuccessPage ? (
          <SuccessPage inResetFlow={inResetFlow} />
        ) : (
          showResetOtp ? <ResetPasswordOtp stateTokenResp={stateTokenResp} showSuccess ={()=> setShowSuccessPage(true)}/> :
            <Card
              sx={{
                width: ["100%", "50%"],
                px: ["16px", 0],
                mt: [10, 200],
              }}
              data-testid="setPassword-card"
            >
              <Box
                data-testid="setPassword-card-heading"
                mt={25}
                sx={{
                  flexDirection: "column",
                }}
              >
                <Heading
                  data-testid="setPassword-card-heading-text"
                  color="royalBlue_700"
                >
                  {searchParams.get("code") ? t("Registration.reset_password_label") : t("Registration.password_lable")}
                </Heading>
                <Box mt={40} data-testid="setPassword-card-input-wrapper">
                  <Label
                    data-testid="setPassword-card-input-label"
                    color="shade_600"
                    sx={{ lineHeight: "27.2px" }}
                  >
                    {t("Registration.enter_password")}
                  </Label>
                  <Input
                    data-testid="setPassword-card-input-value"
                    type="password"
                    value={password}
                    onChange={(e: any) => {
                      setPassword(e.target.value);
                    }}
                    required
                  />
                </Box>
                <Box mt={20} data-testid="setPassword-card-input-wrapper-2">
                  <Label
                    data-testid="setPassword-card-input-wrapper-label-2"
                    color="shade_600"
                    sx={{ lineHeight: "27.2px" }}
                  >
                    {t("Registration.reenter_password")}
                  </Label>
                  <Input
                    data-testid="setPassword-card-input-wrapper-label-value"
                    type="password"
                    value={matchPassword}
                    onChange={(e: any) => {
                      TagManager.dataLayer({
                        dataLayer: {
                          event: "Click_event",
                          Page_title: "User registration | Choose password",
                          Action: "Confirm password "
                        }
                      });
                      setMatchPassword(e.target.value);
                    }}
                    required
                  />
                </Box>
                {state.unmatch && (
                  <Flex pt={2} data-testid="setPassword-card-password-error">
                    <ErrorIcon
                      color={"raspberry_500"}
                      data-testid="setPassword-card-password-error-icon"
                    />
                    <Text
                      data-testid="setPassword-card-password-error-icon-text"
                      pl={2}
                      color="raspberry_500"
                      sx={{
                        fontSize: "subText",
                        fontWeight: "weight_400",
                      }}
                    >
                      {t("Registration.password_match")}
                    </Text>
                  </Flex>
                )}
                <Flex>
                  <ul
                    sx={{
                      fontSize: "subText",
                      color: "shade_400",
                      lineHeight: "15.4px",
                    }}
                    data-testid="setPassword-card-password-error-hints"
                  >
                    <li
                      sx={{
                        paddingTop: 2,
                        color: `${state.min8Char ? "red" : "shade_400"}`,
                      }}
                      data-testid="setPassword-card-password-error-hints-8char"
                    >
                      {t("Registration.minimum_char")}
                    </li>
                    <li
                      sx={{
                        paddingTop: 2,
                        color: `${state.upperLowerFreq ? "red" : "shade_400"}`,
                      }}
                      data-testid="setPassword-card-password-error-hints-upper-lower"
                    >
                      {t("Registration.upper_char")}
                    </li>
                    <li
                      sx={{
                        paddingTop: 2,
                        color: `${state.mixLetterNumber ? "red" : "shade_400"}`,
                      }}
                      data-testid="setPassword-card-password-error-hints-mix-letter-number"
                    >
                      {t("Registration.latters_num")}
                    </li>
                    <li
                      sx={{
                        paddingTop: 2,
                        color: `${state.atlestOneSpecialChar ? "red" : "shade_400"
                          }`,
                      }}
                      data-testid="setPassword-card-password-error-hints-special--char"
                    >
                      {t("Registration.special_char")}
                    </li>
                  </ul>
                </Flex>

                <Button
                  data-testid="setPassword-card-submit-btn"
                  mt={50}
                  onClick={(e) =>  handleSubmit(e)}
                  value="Submit"
                  disabled={loading}
                  variant={`${loading ? "gray" : "primary"}`}
                  sx={{
                    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
                    height: 49,
                    width: ["100%", "169px"],
                    borderRadius: "40px",
                    cursor: "pointer",
                  }}
                >
                  {searchParams.get("code") ?t("Registration.reset_password_btn") :t("continue")}
                </Button>
              </Box>
            </Card>

        )}

      </Flex>

    </Flex>
  );
}

export default SetPassword;

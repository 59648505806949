import { useOktaAuth } from "@okta/okta-react";
import { useMsal } from "@azure/msal-react";
import { IS_AZURE_B2C_SCHEME } from "@brinks/common/utils/Config";
import { getCurrentB2CUserRole, getCurrentUser, getCurrentUserForApi } from "@brinks/common/utils";
import { AccountInfo } from "@azure/msal-browser";

export default function useGetUserRole(isAPI: boolean) {

    const { instance } = useMsal();
    const { oktaAuth } = useOktaAuth();
    let currentUserRole: string;

    if (IS_AZURE_B2C_SCHEME) {
        const currentAccount = instance.getActiveAccount();
        currentUserRole = getCurrentB2CUserRole(currentAccount as AccountInfo, isAPI);
    }
    //Azure B2C Integration ends here
    else {
        const token: any = oktaAuth.getAccessToken();
        currentUserRole = isAPI ? getCurrentUserForApi(token) : getCurrentUser(token);
    }
    return currentUserRole;
}
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Api, BusinessRuleLocationServicesTransportOrder } from "../api/Api";

// Define the initial state using that type
const initialState: businessRulesTransportOrdersDetailSliceState = {
  loadingBusinessRulesTransportOrders: false,
  businessRulesTransportOrders: null,
  currentBusinessRulesTransportOrders: null,
};

interface businessRulesTransportOrdersDetailSliceState {
  loadingBusinessRulesTransportOrders: boolean;
  businessRulesTransportOrders:
    | BusinessRuleLocationServicesTransportOrder[]
    | null;
  currentBusinessRulesTransportOrders: BusinessRuleLocationServicesTransportOrder | null;
}

export const getRulesTransportOrders = createAsyncThunk<any, any, {}>(
  "BusinessRuleLocationServicesTransportOrdersResponse",
  (data, thunkAPI) => {
    const res = new Api().businessRules.getRulesTransportOrders(data);
    return res;
  }
);

export const businessRulesTransportOrdersSlice = createSlice({
  name: "businessRulesTransportOrdersSlice",
  initialState,
  reducers: {
    setBusinessRulesTransportOrders: (state, action) => {
      state.businessRulesTransportOrders = action.payload;
    },
    setCurrentBusinessRulesTransportOrders: (state, action) => {
      state.businessRulesTransportOrders = action.payload;
    },
    setLoadingBusinessRulesTransportOrders: (state, action) => {
      state.loadingBusinessRulesTransportOrders = action.payload;
    },
    resetWebFilter: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRulesTransportOrders.pending, (state) => {
        state.loadingBusinessRulesTransportOrders = true;
        state.businessRulesTransportOrders = null;
      })
      .addCase(getRulesTransportOrders.fulfilled, (state, { payload }) => {
        state.loadingBusinessRulesTransportOrders = false;
        state.businessRulesTransportOrders = payload.data;
      })
      .addCase(getRulesTransportOrders.rejected, (state) => {
        state.loadingBusinessRulesTransportOrders = false;
      });
  },
});

export const {
  setBusinessRulesTransportOrders,
  setCurrentBusinessRulesTransportOrders,
  resetWebFilter,
  setLoadingBusinessRulesTransportOrders,
} = businessRulesTransportOrdersSlice.actions;
// Other code such as selectors can use the imported `RootState` type

export default businessRulesTransportOrdersSlice.reducer;


import { IS_BRINKS_THEME, MSAL_BLUBEEM_POLICY_NAME, MSAL_BC_POLICY_NAME, MSAL_DOMAIN_NAME, MSAL_CLIENT_ID } from "@brinks/common/utils/Config";

const MSAL_POLICY_NAME = IS_BRINKS_THEME ? MSAL_BC_POLICY_NAME : MSAL_BLUBEEM_POLICY_NAME;

const b2cPolicies = {
  authorities: {
    signUpSignIn: {
      authority:`https://${MSAL_DOMAIN_NAME}.b2clogin.com/${MSAL_DOMAIN_NAME}.onmicrosoft.com/${MSAL_POLICY_NAME}`, 
    }
  },
  authorityDomain: `${MSAL_DOMAIN_NAME}.b2clogin.com`
}

export const msalConfig = {
  auth: {
    clientId: `${MSAL_CLIENT_ID}`, // This is the ONLY mandatory field that youneed to supply.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: '/', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: true, // If 'true', will navigate back to the original request location before processing the auth code response.
  },
  headers: {
    'Cross-Origin-Opener-Policy': 'same-origin-allow-popups',
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. 'sessionStorage' is more secure, but 'localStorage' gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to 'true' if you are having issues on IE11 or Edge
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
    pollIntervalMilliseconds: 0, // Sets the intervaltiming between authentication redirects/popups. This is important to not let the application render inside the popup window
  }
}

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "@brinks/common/hooks/hooks";
import { resetState } from "@brinks/common/reducers/orderDetailsSlice";
import { Box, Flex, Label, Button, Input, Text, Textarea } from "theme-ui";
import { resetWebFilter } from "@brinks/common/reducers/storeLocationSlice";
import { setsubjetDescription, setFiles, resetFiles } from "@brinks/common/reducers/supportCaseSlice";
import { useNavigate } from "react-router-dom";
import TagManager from "react-gtm-module";
import { FileUpload } from "../FileUpload";
import { DisplayFiles } from "../DispalyFiles";

export default function AddDescription({ nextStep }: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { selectedSubjet, subjetDescription, templates, selectedFiles, selectedCaseMerchant } =
    useAppSelector((state) => state.supportCaseWithMerchantSlice);

    const { selectedLocation } = useAppSelector(
      (state) => state.storeLocationSlice
    );
  
    const { currentMerchant } = useAppSelector(
      (state) => state.merchantsDetailSlice
    );

  return (
    <>
      <Flex
        bg="white"
        sx={{
          p: 35,
          borderBottom: "1px solid",
          borderColor: "borderColor",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: 8,
          flexDirection: "column",
        }}
        data-testid="SupportCaseLocation"
      >
          <Flex sx={{ gap: 3, width: 455, flexDirection: 'column' }}>
            <Box>
              <Label
                  sx={{
                      fontSize: "subText",
                      fontWeight: "body",
                      lineHeight: "register_bag",
                      fontFamily: "body",
                      marginBottom: "8px",
                  }}
              >
                  {t("ManageCase.merchant_name")}
              </Label>
              <Input
                sx={{ height: "61px" }}
                defaultValue={currentMerchant ? currentMerchant.name : ""}
                name="location_name"
                disabled
              />
            </Box>
            <Box>
              <Label
                  sx={{
                      fontSize: "subText",
                      fontWeight: "body",
                      lineHeight: "register_bag",
                      fontFamily: "body",
                      marginBottom: "8px",
                  }}
              >
                  {t("support.case_for_location")}
              </Label>
              <Input
                  sx={{ height: "61px" }}
                  defaultValue={selectedLocation ? selectedLocation[0].name : ""}
                  name="location_name"
                  disabled
              />
            </Box>
        </Flex>
      </Flex>
      <Flex
        bg="white"
        mt={32}
        sx={{
          p: 35,
          borderBottom: "1px solid",
          borderColor: "borderColor",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: 8,
          flexDirection: "column",
        }}
        data-testid="SupportCaseLocation"
      >
        <Box>
          <Label
            sx={{
              fontSize: "subText",
              fontWeight: "body",
              lineHeight: "register_bag",
              fontFamily: "body",
              marginBottom: "8px",
            }}
          >
            {t("support.subject")} {` `}
            {selectedSubjet}
          </Label>
          <Label
            sx={{
              fontSize: "subText",
              fontWeight: "body",
              lineHeight: "register_bag",
              fontFamily: "body",
              marginBottom: "8px",
            }}
          >
            <Text
              sx={{
                fontSize: "subText",
                fontWeight: "normal",
                lineHeight: "register_bag",
              }}
            >
              {
                templates?.find(
                  (template) => template.subject === selectedSubjet
                )?.instruction
              }
            </Text>
          </Label>
          <Box sx={{ position: "relative" }}>
            <Textarea
              onChange={(e) => {
                dispatch(setsubjetDescription(e.target.value));
              }}
              value={subjetDescription}
              sx={{
                color: "seal_bag",
                width: "100%",
                height: 150,
                border: "1px solid",
                borderColor: "shade_200",
                borderRadius: "6px",
                fontSize: "subText",
                fontWeight: "normal",
                lineHeight: "register_bag",
                whiteSpace: "pre-wrap"
              }}
            />
            <FileUpload selectedFiles={selectedFiles} handleResetFiles= {() => dispatch(resetFiles())} handleFileChange={(files) => dispatch(setFiles(files))} right={15} bottom={15}/>
          </Box>
        </Box>
        <DisplayFiles selectedFiles={selectedFiles} />
      </Flex>
      <Flex
        mt={32}
        sx={{
          flexDirection: "row-reverse",
        }}
      >
        <Button
          disabled={!subjetDescription}
          onClick={(e: any) => {
            nextStep();
            TagManager.dataLayer({
              dataLayer: {
                event: "Click_event",
                Page_title: "Support | Create a case - Select Subject",
                Action: "Next",
              },
            });
          }}
          sx={{
            "&:disabled": {
              bg: "shade_200",
              color: "shade_400",
              cursor: "not-allowed",
            },
            width: 164,
            height: 48,
            borderRadius: 40,
            px: 12,
            py: 16,
          }}
        >
          {t("ManageSealBags.next")}
        </Button>
        <Button
          onClick={() => {
            TagManager.dataLayer({
              dataLayer: {
                event: "Click_event",
                Page_title: "Support | Create a case - Select Subject",
                Action: "Cancel",
              },
            });
            dispatch(resetState());
            dispatch(resetWebFilter());
            navigate(`/support`);
          }}
          mr={20}
          variant="muted"
          data-testid="register-sealbag-step1-next-btn"
          sx={{
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
            height: 49,
            width: ["100%", "169px"],
            pointerEvents: `auto`,
            borderRadius: "40px",
            cursor: "pointer",
            backgroundColor: "background",
            color: "royalBlue_500",
            border: "1px solid",
            borderColor: "royalBlue_500",
          }}
        >
          {t("ManageSealBags.cancel")}
        </Button>
      </Flex>
    </>
  );
}

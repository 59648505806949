// ---------------------------------------------------------
// EXTERNAL IMPORTS
// ---------------------------------------------------------
import TagManager from "react-gtm-module";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Divider, Flex, Heading, Text } from "theme-ui";

// ---------------------------------------------------------
// INTERNAL IMPORTS
// ---------------------------------------------------------
import Loader from "../../../Shared/Loader";
import GlobalSearch from "../AddNewUser/GlobalSearch";

import { UserType } from "@brinks/common/constants/users";
import { StoreLocation } from "@brinks/common/api/Api";
import { useUserWithLocations } from "../../../Hooks/useUserWithLocations";
import { UserAccountMerchantSelector } from "./UserAccountMerchantSelector";
import { RowMerchantElementToLocation } from "./RowMerchantElementToLocation";
import { IS_AZURE_B2C_SCHEME } from "@brinks/common/utils/Config";

// ---------------------------------------------------------
// TYPES
// ---------------------------------------------------------

interface UserLocationsToSelectProps {
  user: any;
  isEdit: Boolean;
  loading: boolean;
  searchLocation: (search: string) => void;
  locationsFiltered: StoreLocation[];
  selectedLocations: StoreLocation[];
  searchMerchant: (merchantId: null | string) => void;
  handleSelectLocation: (id: string, value: boolean) => void;
}
export default function UserLocationsToSelect({
  user,
  isEdit,
  loading,
  searchLocation,
  searchMerchant,
  locationsFiltered,
  selectedLocations,
  handleSelectLocation,
}: UserLocationsToSelectProps) {

  const { t } = useTranslation();
  const [search, setSearch] = useState('')
  const { merchants, loading: loadingUsersOptions } = useUserWithLocations({});

  const createMarkup = (html: any) => {
    return { __html: html };
  };

  const handleSearch = (search: string) => {
    setSearch(search)
    searchLocation(search);
  };

  const color: string = isEdit ? "shade_400" : "shade_800";

  return (
    <>
      <Flex sx={{ justifyContent: "space-between" }}>
        <Flex sx={{ flex: 1, alignItems: "center", justifyContent: "space-between" }} >
          {isEdit && (
            <>
              <Flex sx={{ alignItems: "center", justifyContent: "space-between" }} >
                <Heading sx={{ fontSize: "medium" }}>
                  {t("AddNewUser.locations")}
                </Heading>
                <Box
                  ml={3}
                  px={2}
                  py={1}
                  sx={{
                    width: 58,
                    borderRadius: 4,
                    bg: "borderColor",
                    color: "shade_500",
                    textAlign: "center",
                  }}
                >
                  {locationsFiltered && locationsFiltered.length}
                </Box>
              </Flex>
              <Flex sx={{ gap: 2, alignItems: "center", justifyContent: "space-between" }}>
                <GlobalSearch placeholder="Search locations" searchLocation={(search) => handleSearch(search)} />
                <UserAccountMerchantSelector
                  merchants={merchants}
                  onChange={(merchantId) => searchMerchant(merchantId)}
                />
              </Flex>
            </>
          )}
        </Flex>
      </Flex>
      <Flex
        data-testid="location"
        sx={{
          px: 36,
          pt: 26,
          mt: 35,
          width: "100%",
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          flexDirection: "column",
          pb: `${isEdit && '21px'}`,
          borderColor: "borderColor",
          borderBottom: `${isEdit && "2px solid"}`,
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          backgroundColor: `${isEdit ? "rgba(240, 242, 244, 0.28)" : "white"}`,
        }}
      >
        <Flex sx={{ width: "100%" }}>
          <Text color={color} sx={{ flex: 2 }}>
            Location Names
          </Text>
          <Text color={color} sx={{ flex: 2 }}>
            {t("UserList.merchant")}
          </Text>
          <Text color={color} sx={{ flex: 3 }}>
            Address
          </Text>
          {isEdit && user?.type === UserType.STANDARD_USER && (
            <Text color={color} sx={{ flex: 1, textAlign: "right" }}>
              Assign
            </Text>
          )}
        </Flex>
        {!isEdit && <Divider color="divider" mt={20} />}
      </Flex>
      <Box sx={{ height: "635px", overflowY: "scroll" }}>
        {loading || loadingUsersOptions ? (
          <Box>
            <Loader />
          </Box>
        ) : (
          locationsFiltered &&
          locationsFiltered.length > 0 &&
          locationsFiltered.map((location: any, index: number) => {
            const isLocationsSelected = selectedLocations.find((selectedLocation) => location.id === selectedLocation.id) !== undefined;

            return (
              <Flex
                bg="white"
                key={index}
                data-testid="location"
                sx={{
                  px: 36,
                  pt: 26,
                  pb: 21,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Flex sx={{ width: "100%", flexWrap: "wrap" }}>
                  <Text
                    sx={{
                      flex: 2,
                      flexWrap: "wrap",
                      color: "shade_800",
                      fontWeight: "weight_400",
                    }}
                    dangerouslySetInnerHTML={createMarkup(
                      location?.name.replace(new RegExp(search, 'gi'), `<mark>${search}</mark>`)
                    )}
                  >

                  </Text>
                  <Box sx={{ flex: 2, flexWrap: "wrap", color: "shade_400" }}>
                    <RowMerchantElementToLocation location={location} />
                  </Box>
                  <Text
                    sx={{
                      flex: 3,
                      flexWrap: "wrap",
                      color: "shade_800",
                      fontWeight: "weight_400",
                    }}
                  >
                    {location?.physicalLocation?.street}{" "}
                    {location?.physicalLocation?.houseNumber}{" "}
                    {location?.physicalLocation?.postalCode}{" "}
                    {location?.physicalLocation?.city}{" "}
                  </Text>
                  {
                    //Azure B2C Integration starts here

                    IS_AZURE_B2C_SCHEME ?

                      isEdit && (
                        <Flex sx={{ flex: 1, justifyContent: "flex-end" }}>
                          <input
                            width="24px"
                            height="24px"
                            type="checkbox"
                            disabled={user?.type === UserType.SUPER_USER ? true : false}
                            className="checkbox-location"
                            checked={isLocationsSelected}
                            onChange={(e) => {
                              TagManager.dataLayer({
                                dataLayer: {
                                  event: "Click_event",
                                  Page_title: "User management | Update user account",
                                  Action: isLocationsSelected ? "Assign location" : "Unassign location"
                                }
                              });

                              handleSelectLocation(location.id, !isLocationsSelected);
                            }}
                          />
                        </Flex>
                      )
                      //Azure B2C Integration ends here
                      :
                      isEdit && user?.type === UserType.STANDARD_USER && (
                        <Flex sx={{ flex: 1, justifyContent: "flex-end" }}>
                          <input
                            width="24px"
                            height="24px"
                            type="checkbox"
                            disabled={false}
                            className="checkbox-location"
                            checked={isLocationsSelected}
                            onChange={(e) => {
                              TagManager.dataLayer({
                                dataLayer: {
                                  event: "Click_event",
                                  Page_title: "User management | Update user account",
                                  Action: isLocationsSelected ? "Assign location" : "Unassign location"
                                }
                              });

                              handleSelectLocation(location.id, !isLocationsSelected);
                            }}
                          />
                        </Flex>
                      )

                  }
                </Flex>
              </Flex>
            )
          })
        )}
      </Box>
    </>
  );
}

// ------------------------------------------------------------
// EXTERNAL IMPORTS
// ------------------------------------------------------------
import TagManager from "react-gtm-module";

import { useOktaAuth } from "@okta/okta-react";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import {
  Box,
  Link,
  Flex,
  Close,
  Button,
  Divider,
  Heading,
} from "theme-ui";

import "./index.scss";

// ------------------------------------------------------------
// INTERNAL IMPORTS
// ------------------------------------------------------------
import Period from "./Period";
import Amount from "./Amount";
import Location from "./Location";
import Loader from "../../Shared/Loader";
import PaymentMethods from "./PaymentMethod";
import TransactionType from "./TransactionType";

import { colors } from "@brinks/common/colors";
import { MerchantFilterSelector } from "./MerchantFilterSelector";
import { useAppDispatch, useAppSelector } from "@brinks/common/hooks/hooks";
import { getStoreLocations, setWebLocation } from "@brinks/common/reducers/storeLocationSlice";
import { filterPaymentMethods } from "@brinks/common/constants/transactionTypeData";
import { getCurrentDate, getDateByDays } from "@brinks/common/utils";
import {
  LocationFilter,
  getTransactions,
  PeriodFilterType,
  AmountFilterType,
  PaymentMethodFilter,
  setSelectedPeriodFilter,
  setSelectedAmountFilter,
  setSelectedLocationFilter,
  setSelectedPaymentTypeFilter,
  setSelectedPaymentMethodFilter,
} from "@brinks/common/reducers/dashboardSlice";
import { getMerchants } from "@brinks/common/reducers/merchantsDetailSlice";
import useGetUserRole from "../../Hooks/useGetUserRole";

interface Props {
  setIsFiltered: (key: boolean) => void;
  setFilterPanel: (key: boolean) => void;
}

const FilterOverlay = ({ setFilterPanel, setIsFiltered }: Props) => {
  //Azure B2C Integration starts here
  const userRole: string = useGetUserRole(true);
  //Azure B2C Integration ends here

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        Page_title: "Dashboard | Detail view transaction"
      }
    })
  }, []);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const reduxState = useAppSelector((state) => state.dashboardSlice);

  const userType = userRole;

  const { loading, storeLocations, selectedWebLocation } = useAppSelector(
    (state) => state.storeLocationSlice
  );

  const { merchants, loadingMerchants } = useAppSelector(
    (state) => state.merchantsDetailSlice
  );

  const {
    selectedMinAmount,
    selectedMaxAmount,
    selectedAmountType,
    selectedPeriodEndDate,
    selectedPaymentMethod,
    selectedPeriodStartDate,
    selectedQuickTimeFilter,
    selectedPaymentTypeFilter,
  } = reduxState;

  const [isDirty, setIsDirty] = useState(false);
  const [location, setLocation] = useState(selectedWebLocation);
  const [maxAmount, setMaxAmount] = useState(selectedMaxAmount);
  const [minAmount, setMinAmount] = useState(selectedMinAmount);
  const [amountType, setAmountType] = useState(selectedAmountType);
  const [paymentMethod, setPaymentMethod] = useState(selectedPaymentMethod);
  const [merchantOption, setMerchantOption] = useState<null | string>(null);
  const [endDate, setEndDate] = useState<Date>(new Date(selectedPeriodEndDate));
  const [startDate, setStartDate] = useState<Date>(new Date(selectedPeriodStartDate));
  const [advanceTransactionType, setAdvanceTransactionType] = useState(selectedPaymentTypeFilter);


  const onResetAdvanceFilterData = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "Click_event",
        Page_title: "Dashboard | Filter options",
        Action: "Reset"
      }
    });

    setMinAmount(0);
    setMaxAmount(0);
    setLocation([]);
    setIsDirty(false);
    setAmountType('all');
    setMerchantOption(null);
    setPaymentMethod(['all']);
    setEndDate(getCurrentDate());
    dispatch(setWebLocation([]));
    setStartDate(getDateByDays(7));
    setAdvanceTransactionType('all');

    for (let index = 0; index < filterPaymentMethods.length; index++) {
      filterPaymentMethods[index].disabled = false;
    }
  };

  const getFilteredTransactions = () => {
    setIsFiltered(true);

    const periodFilter: PeriodFilterType = {
      selectedPeriodEndDate: new Date(endDate),
      selectedPeriodStartDate: new Date(startDate),
    };

    const amountFilter: AmountFilterType = {
      selectedMinAmount: minAmount,
      selectedMaxAmount: maxAmount,
      selectedAmountType: amountType,
    };

    const paymentFilter: PaymentMethodFilter = {
      selectedPaymentMethod: paymentMethod,
    };

    const locationFilter: LocationFilter = {
      selectedLocation: location.map((data: any) => data.id),
    };

    dispatch(setSelectedPeriodFilter(periodFilter));
    dispatch(setSelectedAmountFilter(amountFilter));
    dispatch(setSelectedLocationFilter(locationFilter));
    dispatch(setSelectedPaymentMethodFilter(paymentFilter));
    dispatch(setSelectedPaymentTypeFilter(advanceTransactionType));

    dispatch(
      getTransactions({
        ...reduxState,
        ...periodFilter,
        ...amountFilter,
        ...paymentFilter,
        ...locationFilter,
        userType: userRole,
        selectedPaymentTypeFilter: advanceTransactionType,
      })
    );
  };

  useEffect(() => {
    setEndDate(new Date(selectedPeriodEndDate));
    setStartDate(new Date(selectedPeriodStartDate));
  }, [selectedQuickTimeFilter]);

  const onUpdateTransactionType = (type: string) => {
    let transactionTypeTmp = type;
    setAdvanceTransactionType(transactionTypeTmp);

    const paymentMethodTmp = [...paymentMethod];

    for (let index = 0; index < filterPaymentMethods.length; index++) {

      const isIndex = filterPaymentMethods[index].transactionType.findIndex(
        (transactionName) => transactionName == type
      );

      if (isIndex === -1) {
        filterPaymentMethods[index].disabled = true;

        const paymentMethodTmpIndex = paymentMethodTmp.indexOf(
          filterPaymentMethods[index].type
        );
        if (paymentMethodTmpIndex > -1) {
          paymentMethodTmp.splice(paymentMethodTmpIndex, 1);
        }
      } else {
        filterPaymentMethods[index].disabled = false;
      }
    }

    setPaymentMethod(paymentMethodTmp);
  };

  const onUpdatePaymentMethod = (paymentMethodSelected: string) => {
    let paymentMethodTmp = [...paymentMethod];

    const isIndex = paymentMethodTmp.findIndex(
      (paymentMethod) => paymentMethod == paymentMethodSelected
    );

    if (isIndex === -1) {
      if (paymentMethodSelected == "all") {
        paymentMethodTmp = [paymentMethodSelected];
      } else {
        paymentMethodTmp = paymentMethodTmp.filter(
          (paymentMethod) => paymentMethod != "all"
        );
        paymentMethodTmp.push(paymentMethodSelected);
      }
    } else {
      paymentMethodTmp.splice(isIndex, 1);
      paymentMethodTmp = paymentMethodTmp.filter((item) => item != "all");
    }

    setPaymentMethod(paymentMethodTmp);
  };

  const onUpdateLocation = (data: any) => {
    setLocation(data);
  };

  useEffect(() => {
    dispatch(getMerchants({ userType: userType }));
  }, []);

  useEffect(() => {
    dispatch(getStoreLocations({ userType: userType }));
  }, [merchants]);

  const locations = useMemo(() => {
    if (!storeLocations) return [];

    const locations = storeLocations;

    return merchantOption
      ? locations.filter((location) => location.merchantId === merchantOption)
      : locations;

  }, [loading, merchantOption, storeLocations])

  if (loading || loadingMerchants) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  return (
    <Flex
      bg={"white"}
      data-testid="advanceFilter"
      sx={{
        top: 0,
        right: 0,
        zIndex: 99,
        pl: "24px",
        pr: "24px",
        height: "100%",
        width: "526px",
        position: 'fixed',
        overflow: "scroll",
        transition: '0.8s ease',
        flexDirection: "column",
        justifyContent: "space-between",
        boxShadow: "-8px 0px 24px rgba(0, 0, 0, 0.1);",
      }}
    >
      <Flex
        data-testid="advanceFilterFlex"
        sx={{ pt: "30px", justifyContent: "space-between" }}
      >
        <Heading
          sx={{ fontSize: "medium" }}
          data-testid="advanceFilter-heading"
        >
          {t("Dashboard.filter_transactions")}
        </Heading>
        <Box>
          <Link
            onClick={() => onResetAdvanceFilterData()}
            style={{
              right: 65,
              paddingTop: 5,
              cursor: "pointer",
              color: colors.blue,
              position: "absolute",
            }}
          >
            {t("Dashboard.clear_filter")}
          </Link>
          <Close
            style={{ cursor: "pointer" }}
            data-testid="advanceFilter-heading-box"
            onClick={() => {
              TagManager.dataLayer({
                dataLayer: {
                  event: "Click_event",
                  Page_title: "Dashboard | Detail view transaction",
                  Action: "Open | Close toggle"
                }
              });
              setFilterPanel(false);
              document.querySelector("#overlay")?.classList.remove("overlay");
              document.body.style.overflow = "scroll";
            }}
          />
        </Box>
      </Flex>
      <Flex sx={{ width: '100%', marginTop: '20px' }}>
        <MerchantFilterSelector
          isDirty={isDirty}
          value={merchantOption}
          merchants={merchants || []}
          onChange={(value) => {
            setMerchantOption(value);
            setIsDirty(true);
            dispatch(setWebLocation(null));
          }}
        />
      </Flex>
      <Location
        disabled={!isDirty}
        locations={locations}
        onUpdateLocation={(data) => {
          TagManager.dataLayer({
            dataLayer: {
              event: "Click_event",
              Page_title: "Dashboard | Filter options",
              Action: "Select location"
            }
          });
          onUpdateLocation(data);
        }}
      />

      <Divider color="divider" mt={15} />
      <Period
        endDate={endDate}
        startDate={startDate}
        setEndDate={(data) => {
          TagManager.dataLayer({
            dataLayer: {
              event: "Click_event",
              Page_title: "Dashboard | Filter options",
              Action: "Select date end " + data
            }
          });
          setEndDate(data);
        }}
        setStartDate={(data) => {
          TagManager.dataLayer({
            dataLayer: {
              event: "Click_event",
              Page_title: "Dashboard | Filter options",
              Action: "Select date start " + data
            }
          });
          setStartDate(data);
        }}
      />
      <Divider color="divider" mt={15} />
      <Amount
        minAmount={minAmount}
        maxAmount={maxAmount}
        amountType={amountType}
        setMinAmount={(data) => {
          TagManager.dataLayer({
            dataLayer: {
              event: "Click_event",
              Page_title: "Dashboard | Filter options",
              Action: "Min amount " + (data || 'is empty')
            }
          });
          setMinAmount(data);
        }}
        setMaxAmount={(data) => {
          TagManager.dataLayer({
            dataLayer: {
              event: "Click_event",
              Page_title: "Dashboard | Filter options",
              Action: "Max amount " + (data || 'is empty')
            }
          });
          setMaxAmount(data);
        }}
        setAmountType={(data) => {
          TagManager.dataLayer({
            dataLayer: {
              event: "Click_event",
              Page_title: "Dashboard | Filter options",
              Action: data
            }
          });
          setAmountType(data);
        }}
      />
      <Divider color="divider" mt={15} />
      <TransactionType
        advanceTransactionType={advanceTransactionType}
        onUpdateTransactionType={(data) => {
          TagManager.dataLayer({
            dataLayer: {
              event: "Click_event",
              Page_title: "Dashboard | Filter options",
              Action: "Select Transaction type " + data
            }
          });
          onUpdateTransactionType(data);
        }}
      />
      <Divider color="divider" mt={15} />
      <PaymentMethods
        paymentMethod={paymentMethod}
        advanceTransactionType={advanceTransactionType}
        onUpdatePaymentMethod={(data) => {
          TagManager.dataLayer({
            dataLayer: {
              event: "Click_event",
              Page_title: "Dashboard | Filter options",
              Action: "Select Payment method " + data
            }
          });
          onUpdatePaymentMethod(data);
        }}
      />
      <Button
        variant="primary"
        data-testid="btn-filter-shwo-result"
        sx={{
          mt: 50,
          mb: 50,
          width: "478px",
          height: "48px",
          cursor: "pointer",
          borderRadius: "38px",
        }}
        onClick={() => {
          TagManager.dataLayer({
            dataLayer: {
              event: "Click_event",
              Page_title: "Dashboard | Filter options",
              Action: "Show results"
            }
          });
          setFilterPanel(false);
          getFilteredTransactions();
          document.querySelector("#overlay")?.classList.remove("overlay");
          document.body.style.overflow = "scroll";
        }}
      >
        {t("Dashboard.show_results")}
      </Button>
    </Flex>
  );
};

export default FilterOverlay;

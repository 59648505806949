import React, { useEffect, useState } from "react";
// @ts-ignore
import Modal from "react-modal";
import { Box, Flex, Button } from "theme-ui";
import { useTranslation } from "react-i18next";
import { CloseIcon } from "@brinks/common/Icons/Components/close_modal";
import { WarningIcon } from "@brinks/common/Icons/Components/warning";
import TagManager from "react-gtm-module";
import { IS_AZURE_B2C_SCHEME } from "@brinks/common/utils/Config";

const bg = {
  overlay: {
    position: "fixed",
    zIndex: 1020,
    top: 0,
    left: 0,
    width: "420",
    height: "322",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  content: {
    backgroundColor: "white",
    width: "45rem",
    maxWidth: "23%",
    padding: "30px",
    maxHeight: "50%",
    overflowY: "auto",
    position: "relative",
    borderRadius: "16px",
  },
};

export default function ConfirmationModal({
  isConfirmationModalOpen,
  toggleConfirmationModal,
  submit,
  confirmationMsg,
  btnText,
  orderType
}: any) {
  const [tagType, setTagType] = useState("");
  const [clicked, setClicked] = useState(false);
  useEffect(() => {
    if (orderType === "pickup") {
      setTagType("Order Pickup - Confirm Order");
    } else {
      setTagType("Order Change - Confirm Order");
    }
  }, []);
  const { t } = useTranslation();
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        Page_title: "Order management | " + tagType
      }
    });
  }, []);
  //Azure B2C Integration starts here
  const handleClick = () => {
    setClicked(true);
    submit();
  };
  //Azure B2C Integration ends here
  return (
    <Modal
      backdropOpacity={0.5}
      closeTimeoutMS={500}
      isOpen={isConfirmationModalOpen}
      style={bg}
      transparent={true}
      center
    >
      <Box>
        <Flex sx={{ flexDirection: "row-reverse" }}>
          <Box
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              TagManager.dataLayer({
                dataLayer: {
                  event: "Click_event",
                  Page_title: "Order management | " + tagType,
                  Action: "Confirm Order Change X-close"
                }
              });
              toggleConfirmationModal(!isConfirmationModalOpen);
            }}
          >
            <CloseIcon
              color={"shade_300"}
              style={{
                cursor: "pointer",
              }}
            />
          </Box>
        </Flex>
        <Flex
          mt={10}
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column-reverse",
          }}
        >
          <WarningIcon color={"shade_300"} />
        </Flex>
        <Flex
          pt={10}
          sx={{
            fontFamily: "heading",
            fontWeight: "bold",
            fontSize: "heading_4",
            alignContent: "center",
            lineHeight: "register_bag",
            flexDirection: "column-reverse",
            textAlign: "center",
          }}
        >
          {t("ManageSealBags.confirmation")}
        </Flex>
        <Flex
          pt={10}
          sx={{
            fontFamily: "body",
            fontWeight: "normal",
            fontSize: "body",
            lineHeight: "label",
            color: "beaver",
            textAlign: "center",
          }}
        >
          {confirmationMsg}
        </Flex>
        <Flex sx={{ flexDirection: "row-reverse" }}>
          {
            //Azure B2C Integration starts here
            IS_AZURE_B2C_SCHEME ?
              <Button
                mt={20}
                variant="danger"
                disabled={clicked}
                onClick={() => {
                  handleClick();
                }}
                sx={{
                  boxShadow: clicked ? "0px 4px 8px rgba(255, 0, 0, 0.3)" : "0px 8px 16px rgba(0, 0, 0, 0.1)",
                  height: 49,
                  pointerEvents: `auto`,
                  borderRadius: "40px",
                  cursor: clicked ? "none" : "pointer",
                  float: "right",
                  backgroundColor: "red", // Change background color
                  color: clicked ? "gray" : "white", // Change text color
                }}
              >
                {btnText}
              </Button>
              //Azure B2C Integration ends here 
              :
              <Button
                mt={20}
                variant="primary"
                onClick={() => {
                  TagManager.dataLayer({
                    dataLayer: {
                      event: "Click_event",
                      Page_title: "Order management | " + tagType,
                      Action: "Confirm Order Change Yes, place order"
                    }
                  });
                  submit();
                }}
                data-testid="register-sealbag-step1-next-btn"
                sx={{
                  boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
                  height: 49,
                  pointerEvents: `auto`,
                  borderRadius: "40px",
                  cursor: "pointer",
                  float: "right",
                }}
              >
                {btnText}
              </Button>
          }
          <Button
            mt={20}
            mr={20}
            variant="muted"
            onClick={() => {
              TagManager.dataLayer({
                dataLayer: {
                  event: "Click_event",
                  Page_title: "Order management | " + tagType,
                  Action: "Confirm Order Change Go back"
                }
              });
              toggleConfirmationModal(!isConfirmationModalOpen);
            }}
            data-testid="register-sealbag-step1-next-btn"
            sx={{
              boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
              height: 49,
              width: ["100%", "120px"],
              pointerEvents: `auto`,
              borderRadius: "40px",
              cursor: "pointer",
              backgroundColor: "white",
              color: "royalBlue_500",
              border: "1px solid",
              brderColor: "royalBlue_500",
            }}
          >
            {t("ManageSealBags.go_back")}
          </Button>
        </Flex>
      </Box>
    </Modal>
  );
}

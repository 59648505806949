// @ts-nocheck

import moment from "moment-timezone";
import TagManager from "react-gtm-module";
import { Alert, Box, Close, Flex, Heading } from "theme-ui";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMemo, useEffect, useState } from "react";
import Loader from "../../../Shared/Loader";
import PageHeader from "../../../Shared/PageHeader";
import Wrapper from "../../../Shared/Wrapper/Wrapper";
import GlobalSearch from "../../Users/AddNewUser/GlobalSearch";
import { Table } from "../../ReactTable/ReactTable";
import { SupportListStatusSelector } from "./SupportListStatusSelector";
import { useAppDispatch, useAppSelector } from "@brinks/common/hooks/hooks";
import { SupportListMerchantSelector } from "./SupportListMerchantSelector";
import { getCases, setSuccessSupportCaseMsg } from "@brinks/common/reducers/supportCaseSlice";
import { getMerchants } from "@brinks/common/reducers/merchantsDetailSlice";
import { SuccessIcon } from "@brinks/common/Icons/Components/success_msg";
import { BackIcon } from "@brinks/common/Icons/Components/back";
import useGetUserRole from "../../../Hooks/useGetUserRole";

export const SupportList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const [statusFilter, setStatusFilter] = useState<null | string>(null);
  const [countryFilter, setCountryFilter] = useState<null | string>(null);
  const [subjectFilter, setSubjectFilter] = useState<null | string>(null);
  const [merchantFilter, setMerchantFilter] = useState<null | string>(null);
  const [locationFilter, setLocationFilter] = useState<null | string>(null);

  const { loading, cases, successSupportCase } = useAppSelector((state) => state.supportCaseSlice);
  const { loadingMerchants } = useAppSelector((state) => state.merchantsDetailSlice);

  //Azure B2C Integration starts here
  const userType: string = useGetUserRole(true);
  //Azure B2C Integration ends here

  useEffect(() => {
    if (successSupportCase) {
      setTimeout(() => {
        dispatch(setSuccessSupportCaseMsg(false));
      }, 6000);
    }
  }, [successSupportCase]);

  useEffect(() => {
    dispatch(getMerchants({ userType: userType }));
  }, []);

  useEffect(() => {
    dispatch(getCases({}));
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        Page_title: "Support cases | Overview",
      },
    });
  }, []);

  const dataOriginID = (e: any) => {
    navigate(`/support/details/${e.caseSalesforceId}`);
  };

  const columns = useMemo(
    () => [
      {
        id: "caseNumber",
        disableFilters: true,
        accessor: "caseNumber",
        Header: () => t("support.case_number"),
      },
      {
        width: "150",
        id: "locationName",
        disableFilters: true,
        accessor: "locationName",
        Header: () => t("support.location_name"),
      },
      {
        width: "150",
        id: "countryCode",
        disableFilters: true,
        accessor: "countryCode",
        Header: () => t("country"),
      },
      {
        width: "200",
        id: "subject",
        accessor: "subject",
        disableFilters: true,
        Header: () => t("support.subject"),
      },
      {
        id: "createdDate",
        disableFilters: true,
        Header: () => t("support.date_created"),
        accessor: (d: any) => moment(d.createdDate).format("DD/MM/YYYY"),
      },
      {
        disableFilters: true,
        id: "lastModifiedDate",
        Header: () => t("support.date_last_modified"),
        accessor: (d: any) => moment(d.lastModifiedDate).format("DD/MM/YYYY"),
      },
      {
        id: "status",
        width: "120",
        accessor: "status",
        disableFilters: true,
        Header: () => t("support.status"),
      },
    ],
    []
  );

  const handleCompareValues = (
    value_one: string,
    value_two: string
  ): boolean => {
    return value_one.toLowerCase().includes(value_two.toLowerCase());
  };

  const supportCases = useMemo(() => {

    if (!cases) return [];

    let casesFiltered = cases;

    if (locationFilter) {
      casesFiltered = casesFiltered.filter(({ locationName }) => {
        return handleCompareValues(locationName, locationFilter);
      });
    }

    if (countryFilter) {
      casesFiltered = casesFiltered.filter(({ countryCode }) => {
        return handleCompareValues(countryCode, countryFilter);
      });
    }

    if (subjectFilter) {
      casesFiltered = casesFiltered.filter(({ subject }) => {
        return handleCompareValues(subject, subjectFilter);
      });
    }

    if (statusFilter) {
      casesFiltered = casesFiltered.filter(({ status }) => {
        return handleCompareValues(status, statusFilter);
      });
    }

    if (merchantFilter) {
      casesFiltered = casesFiltered.filter(({ merchantId }) => {
        return merchantId === merchantFilter;
      });
    }

    return casesFiltered;
  }, [
    statusFilter,
    countryFilter,
    subjectFilter,
    merchantFilter,
    locationFilter,
    cases,
  ]);

  if (loading || loadingMerchants) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  return (
    <>
      <PageHeader>
        <Heading>
          <button
            onClick={(e: any) => window.history.back()}
            style={{ marginRight: "20px", background: "transparent", border: "none", cursor: "pointer" }}
          >
            <BackIcon color={"black"} />
          </button>
          {t("support.overview_cases")}
        </Heading>
        {successSupportCase ? (
          <Alert
            ml={89}
            sx={{
              bg: "fern_50",
              height: "56px",
              width: "361px",
              fontSize: "subText",
              fontWeight: "weight_400",
              color: "fern_850",
            }}
          >
            <>
              <Box mr={20}>
                <SuccessIcon
                  style={{ marginRight: "20px" }}
                  color={"fern_500"}
                />
              </Box>
              {t("support.create_case_success_message")}
              {TagManager.dataLayer({
                dataLayer: {
                  event: "click_event",
                  Page_title: "Support | Create case successfully",
                  Action: "Create casxe Successfull"
                }
              })}
              <Close
                style={{ cursor: "pointer" }}
                ml="auto"
                mr={-2}
                onClick={() => dispatch(setSuccessSupportCaseMsg(false))}
                data-testid="OtpPage-error-alert-close-icon"
              />
            </>
          </Alert>
        ) : (
          ""
        )}
      </PageHeader>
      <Wrapper>
        <Flex
          sx={{
            width: "100%",
            alignItems: "center",
            gap: 2,
            flexWrap: "wrap",
            justifyContent: "flex-between",
          }}
        >
          <GlobalSearch
            searchLocation={(value) => setLocationFilter(value)}
            placeholder={t("CasesFilter.locations_search_placeholder")}
          />
          <GlobalSearch
            searchLocation={(value) => setCountryFilter(value)}
            placeholder={t("CasesFilter.country_search_placeholder")}
          />
          <GlobalSearch
            searchLocation={(value) => setSubjectFilter(value)}
            placeholder={t("CasesFilter.subject_search_placeholder")}
          />
          <SupportListStatusSelector
            onChange={(value) => setStatusFilter(value)}
          />
          <SupportListMerchantSelector
            cases={cases || []}
            onChange={(value) => setMerchantFilter(value)}
          />
        </Flex>
        <Flex
          mt={40}
          bg="white"
          data-testid="SupportList"
          sx={{
            borderRadius: 8,
            flexDirection: "column",
            borderBottom: "1px solid #E2E5E9",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          {cases && cases.length > 0 && (
            <Table
              data={supportCases}
              globalFilter={""}
              columns={columns}
              dataOriginID={(e: any) => dataOriginID(e)}
            />
          )}
        </Flex>
      </Wrapper>
    </>
  );
};

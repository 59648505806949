import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { Api, CashDevice, StoreLocationWithMerchant } from "../api/Api";

interface StoreLocationWithMerchantSlice {
  loading: boolean;
  locationId: string;
  locationName: string;
  cashDevices: CashDevice[];
  externalReferenceSf: string;
  selectedWebLocation: Array<String>;
  storeLocationsWithMerchant: StoreLocationWithMerchant[];
}

export interface LocationQueryParams {
  hasCashDevices?: boolean;
}

export interface GetCashDevicesQueryParams {
  storeId: string;
}

const initialState: StoreLocationWithMerchantSlice = {
  locationId: "",
  loading: false,
  cashDevices: [],
  locationName: "",
  selectedWebLocation: [],
  externalReferenceSf: "",
  storeLocationsWithMerchant: [],
};

function addPropertyIsSelectedByDefault(storeLocations: any) {
  const locations: any =
    storeLocations &&
    storeLocations.map((obj: any) => {
      return { ...obj, isSelected: false };
    });

  return locations;
}

export const getStoreLocationsWithMerchant = createAsyncThunk<
  any,
  LocationQueryParams,
  {}
>("storeLocationsWithMerchant", (data, thunkAPI) => {
  const res = new Api().locationsWithMerchant.getLocationsWithMerchant(data);
  return res;
});

export const getCashDevices = createAsyncThunk<
  any,
  GetCashDevicesQueryParams,
  {}
>("cashDevices", (data, thunkAPI) => {
  const res = new Api().devices.getCashDevices(data);
  return res;
});

export const storeLocationWithMerchantSlice = createSlice({
  name: "storeLocationWithMerchantSlice",
  initialState,
  reducers: {
    resetWebFilter: () => initialState,
    setWebLocation: (state, action) => {
      state.selectedWebLocation = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getStoreLocationsWithMerchant.pending,
        (state: StoreLocationWithMerchantSlice) => {
          state.loading = true;
          state.storeLocationsWithMerchant = [];
        }
      )
      .addCase(
        getCashDevices.pending,
        (state: StoreLocationWithMerchantSlice) => {
          state.loading = true;
          state.cashDevices = [];
        }
      )
      .addCase(
        getStoreLocationsWithMerchant.fulfilled,
        (state: StoreLocationWithMerchantSlice, { payload }: any) => {
          state.loading = false;
          state.storeLocationsWithMerchant = addPropertyIsSelectedByDefault(
            payload.data.locations
          );
        }
      )
      .addCase(
        getCashDevices.fulfilled,
        (state: StoreLocationWithMerchantSlice, { payload }: any) => {
          state.loading = false;
          state.cashDevices = payload.data.devices;
        }
      )
      .addCase(
        getStoreLocationsWithMerchant.rejected,
        (state: StoreLocationWithMerchantSlice) => {
          state.loading = false;
        }
      )
      .addCase(
        getCashDevices.rejected,
        (state: StoreLocationWithMerchantSlice) => {
          state.loading = false;
        }
      );
  },
});

export const { setWebLocation, resetWebFilter } =
  storeLocationWithMerchantSlice.actions;
export default storeLocationWithMerchantSlice.reducer;


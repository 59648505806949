import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { Api, TransactionDetails } from "../api/Api";

export interface TransactionDetailType {
  transactionId: string;
  transactionType: string;
}

// Define a type for the slice state
interface transactionDetailSliceState {
  id: string;
  loading: boolean;
  transactionDetails: TransactionDetails | null;
}

// Define the initial state using that type
const initialState: transactionDetailSliceState = {
  id: "",
  loading: false,
  transactionDetails: null,
};

export const getTransactionDetails = createAsyncThunk<any, any, {}>(
  "transactionDetails",
  (data, thunkAPI) => {
    const res = new Api().transactions.getTransactionDetails(
      data.transactionType,
      data.transactionId
    );
    return res;
  }
);

export const transactionDetailSlice = createSlice({
  name: "transactionDetailSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getTransactionDetails.pending,
        (state: transactionDetailSliceState) => {
          state.loading = true;
          state.transactionDetails = null;
        }
      )
      .addCase(
        getTransactionDetails.fulfilled,
        (state: transactionDetailSliceState, { payload }: any) => {
          state.loading = false;
          state.transactionDetails = payload.data.transactions[0];
        }
      )
      .addCase(
        getTransactionDetails.rejected,
        (state: transactionDetailSliceState) => {
          state.loading = false;
        }
      );
  },
});

export const {} = transactionDetailSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default transactionDetailSlice.reducer;

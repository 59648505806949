import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { groupByDay } from "../utils";
import {
  Api,
  OpeningHour,
  StoreLocationWithContactAndMerchant,
} from "../api/Api";
export interface LocationDetailWithMerchantType {
  locationId: string;
}

export type OpeningTimeMap = Record<string, OpeningHour[]>;

interface locationDetailSliceState {
  id: string;
  loading: boolean;
  openingHours: OpeningTimeMap | null;
  locationDetailsWithMerchant: null | StoreLocationWithContactAndMerchant;
}

// Define the initial state using that type
const initialState: locationDetailSliceState = {
  id: "",
  loading: false,
  openingHours: null,
  locationDetailsWithMerchant: null,
};

export const getLocationDetailsWithMerchant = createAsyncThunk<any, any, {}>(
  "locationDetailsWithMerchant",
  (data, thunkAPI) => {
    const res = new Api().locationsWithMerchant.getLocationDetailsWithMerchant(
      data.locationId,
      {}
    );
    return res;
  }
);

export const locationDetailWithMerchantSlice = createSlice({
  name: "locationDetailWithMerchantSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getLocationDetailsWithMerchant.pending,
        (state: locationDetailSliceState) => {
          (state.loading = true), (state.locationDetailsWithMerchant = null);
        }
      )
      .addCase(
        getLocationDetailsWithMerchant.fulfilled,
        (state: locationDetailSliceState, { payload }: any) => {
          (state.loading = false),
            (state.locationDetailsWithMerchant = payload.data.locations[0]),
            (state.openingHours = groupByDay(
              payload.data.locations[0].openingHours
            ));
        }
      )
      .addCase(
        getLocationDetailsWithMerchant.rejected,
        (state: locationDetailSliceState) => {
          state.loading = false;
        }
      );
  },
});

export const {} = locationDetailWithMerchantSlice.actions;
export default locationDetailWithMerchantSlice.reducer;


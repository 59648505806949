import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { Api, BusinessRule, GetBusinessRulesResponse } from "../api/Api";

// Define the initial state using that type
const initialState: storeLocationBusinessRuleDetailSliceState = {
  loadingBusinessRules: false,
  businessRules: null,
  currentBusinessRule: null,
};

interface storeLocationBusinessRuleDetailSliceState {
  loadingBusinessRules: boolean;
  businessRules: GetBusinessRulesResponse | null;
  currentBusinessRule: BusinessRule | null;
}

export const getLocationBusinessRules = createAsyncThunk<any, any, {}>(
  "GetBusinessRulesResponse",
  (data, thunkAPI) => {
    const res = new Api().locations.getLocationBusinessRules(
      data.storeLocationSalesForce
    );
    return res;
  }
);

export const storeLocationBusinessRulesDetailSlice = createSlice({
  name: "storeLocationBusinessRulesDetailSlice",
  initialState,
  reducers: {
    setStoreLocationBusinessRules: (state, action) => {
      state.businessRules = action.payload;
    },
    setStoreLocationBusinessRule: (state, action) => {
      state.currentBusinessRule = action.payload;
    },
    setLoadingStoreLocationBusinessRule: (state, action) => {
      state.loadingBusinessRules = action.payload;
    },
    resetWebFilter: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getLocationBusinessRules.pending,
        (state: storeLocationBusinessRuleDetailSliceState) => {
          state.loadingBusinessRules = true;
          state.businessRules = null;
        }
      )
      .addCase(
        getLocationBusinessRules.fulfilled,
        (
          state: storeLocationBusinessRuleDetailSliceState,
          { payload }: any
        ) => {
          state.loadingBusinessRules = false;
          state.businessRules = payload.data;
        }
      )
      .addCase(
        getLocationBusinessRules.rejected,
        (state: storeLocationBusinessRuleDetailSliceState) => {
          state.loadingBusinessRules = false;
        }
      );
  },
});

export const {
  setStoreLocationBusinessRules,
  setStoreLocationBusinessRule,
  resetWebFilter,
  setLoadingStoreLocationBusinessRule,
} = storeLocationBusinessRulesDetailSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default storeLocationBusinessRulesDetailSlice.reducer;


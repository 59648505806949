/** @jsxImportSource theme-ui */
import { useState, useEffect, FormEvent } from "react";
import {
  Flex,
  Box,
  Label,
  Text,
  Alert,
  Input,
  Button,
  Heading,
  Card,
  Close,
} from "theme-ui";
import { ErrorIcon } from "@brinks/common/Icons/Components/error";
import { useTranslation } from "react-i18next";
import useTheme from "../../Hooks/useTheme";
import Logo from "@brinks/common/Icons/Logo";
import SuccessPage from "./SuccessPage";
import LeftSidePage from "./LeftSidePage";
import LanguageSelector from "./LanguageSelector";
import TagManager from "react-gtm-module";
import { useSearchParams } from "react-router-dom";
import { REACT_APP_NATIVE_APP_BASE_URL } from "@brinks/common/utils/Config";
import { sendOtp } from "@brinks/common/reducers/authSlice";
import ResetPasswordOtp from "./ResetPasswordOtp";
interface StateProps {
  min8Char: boolean;
  upperLowerFreq: boolean;
  mixLetterNumber: boolean;
  atLeastOneSpecialChar: boolean;
  unmatch: boolean;
}

function ResetPassword() {
  const [searchParams] = useSearchParams();
  const [isTokenError, setIsTokenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [inResetFlow, setInResetFlow] = useState(false);
  const [password, setPassword] = useState("");
  const [matchPassword, setMatchPassword] = useState("");
  const [showSuccessPage, setShowSuccessPage] = useState(false);
  const [showResetOtp, setShowResetOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stateTokenResp, setStateTokenResp] = useState<any>("");
  const { t } = useTranslation();
  const [state, setState] = useState<StateProps>({
    min8Char: false,
    upperLowerFreq: false,
    mixLetterNumber: false,
    atLeastOneSpecialChar: false,
    unmatch: false,
  });
  const themeType = useTheme();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        Page_title: "Password reset | Set new password",
      },
    });
  }, []);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const recoveryToken = searchParams.get("code");
        if (!recoveryToken) {
          setIsTokenError(true);
          return;
        }

        const response = await fetch(
          `${REACT_APP_NATIVE_APP_BASE_URL}/api/v1/authn/recovery/token`,
          {
            method: "POST",
            body: JSON.stringify({ recoveryToken }),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        if (data?.errorId) {
          setIsTokenError(true);
          return;
        }
        setStateTokenResp(data);
        setInResetFlow(true);
      } catch (error) {
        setIsTokenError(true);
      }
    };

    fetchToken();
  }, [searchParams]);

  const validatePassword = (
    password: string,
    matchPassword: string
  ): StateProps => {
    const upperLowerCaseLetters = /^(?=.*[a-z])(?=.*[A-Z])/;
    const numbers = /(?=.*[0-9])/;
    const specialChar = /(?=.*[!@#$%^&*])/;

    return {
      min8Char: password.length < 8,
      upperLowerFreq: !upperLowerCaseLetters.test(password),
      mixLetterNumber: !numbers.test(password),
      atLeastOneSpecialChar: !specialChar.test(password),
      unmatch: password !== matchPassword,
    };
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const newState = validatePassword(password, matchPassword);
    setState(newState);

    if (Object.values(newState).every((value) => !value)) {
      await handleReset();
    } else {
      return;
    }
  };

  const handleReset = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${REACT_APP_NATIVE_APP_BASE_URL}/api/v1/authn/credentials/reset_password`,
        {
          method: "POST",
          body: JSON.stringify({
            stateToken: stateTokenResp?.stateToken,
            newPassword: password,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data?.errorId) {
        const errorSummaryMessage = data.errorCauses[0]?.errorSummary || data.errorSummary;
        setErrorMessage(errorSummaryMessage);
        return;
      }

      localStorage.setItem("stateToken", JSON.stringify(data));
      setStateTokenResp(data);

      if (data?.status === "MFA_REQUIRED") {
        await sendOtp(data);
        setShowResetOtp(true);
      } else {
        setShowSuccessPage(true);
      }
    } catch (error) {
      setIsTokenError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex
      data-testid="resetPassword-wrapper"
      sx={{ alignItems: "center", height: "100%", overflow: "hidden" }}
    >
      <LeftSidePage />
      <Flex
        data-testid="resetPassword-wrapper-lang"
        bg="white"
        sx={{
          width: ["100%", "60%"],
          height: "100vh",
          alignItems: "center",
          flexDirection: "column",
          margin: "0 auto",
          overflowY: "scroll",
        }}
      >
        <Flex
          data-testid="resetPassword-wrapper-lang-text"
          sx={{ alignSelf: "flex-end" }}
          mr={31}
          mt={[10, 30]}
        >
          <LanguageSelector />
        </Flex>

        <Flex sx={{ display: ["block", "none"], mt: [10, 50] }}>
          <Logo themeType={themeType} />
        </Flex>

        {isTokenError && (
          <Alert
            data-testid="activation-error-alert"
            sx={{ mt: [70, 0], mx: [16, 0] }}
          >
            {t("Registration.expired_msg")}
            <Close
              ml="auto"
              mr={-2}
              onClick={() => setIsTokenError(false)}
              data-testid="activation-error-alert-close-icon"
            />
          </Alert>
        )}

        {errorMessage && (
          <Alert
            data-testid="activation-error-alert"
            sx={{ mt: [70, 0], mx: [16, 0] }}
          >
            {errorMessage}
            <Close
              ml="auto"
              mr={-2}
              onClick={() => setErrorMessage("")}
              data-testid="activation-error-alert-close-icon"
            />
          </Alert>
        )}

        {showSuccessPage ? (
          <SuccessPage inResetFlow={inResetFlow} />
        ) : showResetOtp ? (
          <ResetPasswordOtp
            stateTokenResp={stateTokenResp}
            showSuccess={() => setShowSuccessPage(true)}
          />
        ) : (
          <Card
            sx={{ width: ["100%", "50%"], px: ["16px", 0], mt: [10, 200] }}
            data-testid="resetPassword-card"
          >
            <Box
              data-testid="resetPassword-card-heading"
              mt={25}
              sx={{ flexDirection: "column" }}
            >
              <Heading
                data-testid="resetPassword-card-heading-text"
                color="royalBlue_700"
              >
                {searchParams.get("code")
                  ? t("Registration.reset_password_label")
                  : t("Registration.password_label")}
              </Heading>
              <Box mt={40} data-testid="resetPassword-card-input-wrapper">
                <Label
                  data-testid="resetPassword-card-input-label"
                  color="shade_600"
                  sx={{ lineHeight: "27.2px" }}
                >
                  {t("Registration.enter_password")}
                </Label>
                <Input
                  data-testid="resetPassword-card-input-value"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </Box>
              <Box mt={20} data-testid="resetPassword-card-input-wrapper-2">
                <Label
                  data-testid="resetPassword-card-input-wrapper-label-2"
                  color="shade_600"
                  sx={{ lineHeight: "27.2px" }}
                >
                  {t("Registration.reenter_password")}
                </Label>
                <Input
                  data-testid="resetPassword-card-input-wrapper-label-value"
                  type="password"
                  value={matchPassword}
                  onChange={(e) => {
                    TagManager.dataLayer({
                      dataLayer: {
                        event: "Click_event",
                        Page_title: "User registration | Choose password",
                        Action: "Confirm password",
                      },
                    });
                    setMatchPassword(e.target.value);
                  }}
                  required
                />
              </Box>
              {state.unmatch && (
                <Flex pt={2} data-testid="resetPassword-card-password-error">
                  <ErrorIcon
                    color="raspberry_500"
                    data-testid="resetPassword-card-password-error-icon"
                  />
                  <Text
                    data-testid="resetPassword-card-password-error-icon-text"
                    pl={2}
                    color="raspberry_500"
                    sx={{ fontSize: "subText", fontWeight: "weight_400" }}
                  >
                    {t("Registration.password_match")}
                  </Text>
                </Flex>
              )}
              <Flex>
                <ul
                  sx={{
                    fontSize: "subText",
                    color: "shade_400",
                    lineHeight: "15.4px",
                  }}
                  data-testid="resetPassword-card-password-error-hints"
                >
                  {[
                    { key: "min8Char", text: t("Registration.minimum_char") },
                    {
                      key: "upperLowerFreq",
                      text: t("Registration.upper_char"),
                    },
                    {
                      key: "mixLetterNumber",
                      text: t("Registration.latters_num"),
                    },
                    {
                      key: "atLeastOneSpecialChar",
                      text: t("Registration.special_char"),
                    },
                  ].map(({ key, text }) => (
                    <li
                      key={key}
                      sx={{
                        paddingTop: 2,
                        color: state[key as keyof StateProps] ? "red" : "shade_400",
                      }}
                      data-testid={`resetPassword-card-password-error-hints-${key}`}
                    >
                      {text}
                    </li>
                  ))}
                </ul>
              </Flex>

              <Button
                data-testid="resetPassword-card-submit-btn"
                mt={50}
                onClick={handleSubmit}
                value="Submit"
                disabled={loading}
                variant={loading ? "gray" : "primary"}
                sx={{
                  boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
                  height: 49,
                  width: ["100%", "169px"],
                  borderRadius: "40px",
                  cursor: "pointer",
                }}
              >
                {searchParams.get("code")
                  ? t("Registration.reset_password_btn")
                  : t("continue")}
              </Button>
            </Box>
          </Card>
        )}
      </Flex>
    </Flex>
  );
}

export default ResetPassword;

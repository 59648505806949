import type { Theme } from 'theme-ui'

const themeType = localStorage.getItem('theme-ui-color-mode');

let themeFont = "'Nunito', sans-serif";

if (themeType === 'default') {
  themeFont = "'Nunito', sans-serif";
} else {
  themeFont = "'Nunito Sans', sans-serif";
}

export const theme: Theme = {
  layout: {
    header: {
      color: 'black',
      backgroundColor: 'white',
      justifyContent: 'space-between'
    },
    footer: {
      color: 'white',
      backgroundColor: 'black',
    },
  },
  text: {
    default: {
      color: 'text',
      fontSize: 'body',
      lineHeight: 'body',
      fontWeight: 'body'
    },
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '0.2em',
    },
    heading: {
      fontFamily: themeFont,
      fontWeight: 'heading',
      lineHeight: 'heading',
      fontSize: 'heading_4'
    },
  },
  forms: {
    input: {
      border: "1px solid",
      borderColor: "borderColor",
      p: "17px",
      borderRadius: "6px",
    }
  },
  alerts: {
    primary: {
      color: '#EB1447',
      bg: '#FFBABA',
    },
    muted: {
      color: 'text',
      bg: 'muted',
    },
  },
  fonts: {
    body: 'Inter, sans-serif',
    heading: themeFont,
  },
  colors: {
    text: '#000',
    background: '#F0F2F4',
    primary: '#0F61D8',
    secondary: '#06473A',
    secondary_150: '#B8F9ED',
    secondary_300: '#71F4DA',
    secondary_500: '#11CCA8',
    secondary_700: '#0C8D74',
    accent: '#609',
    muted: '#f6f6f6',
    shade_800: '#0F223D',
    shade_300: '#A3B0C2',
    shade_500: '#476185',
    shade_600: '#264673',
    shade_400: '#667B99',
    shade_700: '#09326D',
    shade_200: '#C3CAD5',
    shade_900: '#0D1726',
    blue: '#0A408F',
    royalBlue_150: '#B7D3FA',
    royalBlue_300: '#70A6F5',
    royalBlue_500: '#0F61D8',
    royalBlue_700: '#0A408F',
    royalBlue_850: '#052048',
    turquoise: '#0C8D74',
    divider: '#E2E5E9',
    borderColor: '#E2E5E9',
    beaver: '#667B99',
    seal_bag: '#476185',
    total: '#4DB366',
    shade_50: '#F0F2F4',
    fern_50: '#EDF7F0',
    fern_500: '#4DB366',
    fern_700: '#2E6B3D',
    fern_850: '#17361F',
    raspberry_50: '#FDE7ED',
    raspberry_300: '#F37291',
    raspberry_500: '#EB1447',
    raspberry_700: '#8D0C2B',
    transactionIconBorders: "#A3B0C2",
    icon_standard: "#A3B0C2",
    icon_active: "#0F61D8",
    search_background: "#E5F4FF",
    cards_transaction: "#0F61D8",
    cash_transaction: "#0A408F",
    clicks_transaction: "#11CCA8",
    order_primary: "#A3B0C2",
    order_secondary: "#11CCA8",
    modes: {
      brinks: {
        text: '#000',
        background: '#EDF4FE',
        primary: '#0A4A8E',
        secondary: '#E67632',
        secondary_150: '#B8F9ED',
        secondary_300: '#F9C940',
        secondary_500: '#6199C4',
        secondary_700: '#0C8D74',
        secondry_850: '#06473A',
        accent: '#609',
        muted: '#f6f6f6',
        shade_800: '#0F223D',
        shade_300: '#6199C4',
        shade_500: '#476185',
        shade_600: '#264673',
        shade_400: '#667B99',
        shade_700: '#052048',
        shade_200: '#C3CAD5',
        shade_900: '#0D1726',
        blue: '#052048',
        royalBlus_150: '#B9CFDF',
        royalBlue_300: '#6199C4',
        royalBlue_500: '#0A4A8E',
        royalBlue_700: '#052048',
        royalBlue_850: '#052048',
        turquoise: '#E67632',
        divider: '#DBE6F5',
        borderColor: '#DBE6F5',
        beaver: '#667B99',
        seal_bag: '#476185',
        total: '#4DB366',
        shade_50: '#EDF4FE',
        fern_50: '#EDF7F0',
        fern_500: '#4DB366',
        fern_700: '#2E6B3D',
        fern_850: '#17361F',
        raspberry_300: '#F37291',
        raspberry_500: '#EB1447',
        raspberry_700: '#8D0C2B',
        transactionIconBorders: "#A3B0C2",
        icon_standard: "#A3B0C2",
        icon_active: "#0A4A8E",
        search_background: "#E5F4FF",
        cards_transaction: "#97CAEB",
        cash_transaction: "#0A4A8E",
        clicks_transaction: "#2C9942",
        order_primary: "#A3B0C2",
        order_secondary: "#6199C4",
      }
    }
  },
  fontSizes: {
    body: '16px',
    heading_4: '24px',
    subText: '14px',
    small: '12px',
    medium: '20px',
    large: '32px',
    location_lable: '28px'
  },
  fontWeights: {
    body: 600,
    heading: 700,
    bold: 700,
    weight_500: 500,
    weight_400: 400
  },
  lineHeights: {
    body: '22.4px',
    heading: '26.4px',
    Lede: 1.4,
    label: '170%',
    location_lable: '110%',
    register_bag: '140%'
  },
  buttons: {
    primary: {
      // you can reference other values defined in the theme
      color: 'white',
      bg: 'primary',
      cursor: 'pointer'
    },
    secondary: {
      color: 'text',
      bg: 'secondary',
    },
    gray: {
      color: 'background',
      bg: 'gray',
    },
    default: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      gap: 2,
      px: 40,
      py: 16,
      color: "white",
      backgroundColor: "royalblue_500",
      border: "1px solid",
      borderColor: "royalBlue_500",
      borderRadius: 40,
      boxShadow: " 0px 8px 16px rgba(0, 0, 0, 0.1)",
      cursor: "pointer",
      '&:hover': {
        color: 'white',
        bg: 'royalBlue_700',
      }
    },
    //Azure B2C Integration starts here
    danger: {
      color: 'white',
      bg: 'red',
      cursor: 'pointer'
    },
    //Azure B2C Integration ends here
    ghost: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      gap: 2,
      px: 40,
      py: 16,
      color: "royalBlue_500",
      backgroundColor: "transparent",
      border: "1px solid",
      borderColor: "royalBlue_500",
      borderRadius: 40,
      boxShadow: " 0px 8px 16px rgba(0, 0, 0, 0.1)",
      cursor: "pointer",
      '&:hover': {
        color: 'royalBlue_700',
        borderColor: "royalBlue_700"
      }
    }
  }
}

// ---------------------------------------------------------
// EXTERNAL IMPORTS
// ---------------------------------------------------------
import { configureStore } from "@reduxjs/toolkit";

// ---------------------------------------------------------
// INTERNAL IMPORTS
// ---------------------------------------------------------
import authSlice from "../reducers/authSlice";
import userSlice from "../reducers/userSlice";
import dashboardSlice from "../reducers/dashboardSlice";
import supportCaseSlice from "../reducers/supportCaseSlice";
import orderDetailsSlice from "../reducers/orderDetailsSlice";
import storeLocationSlice from "../reducers/storeLocationSlice";
import locationDetailSlice from "../reducers/locationDetailSlice";
import registerSealbagSlice from "../reducers/registerSealbagSlice";
import transactionDetailSlice from "../reducers/transactionDetailSlice";
import supportCaseWithMerchantSlice from "../reducers/supportCaseWithMerchantSlice";
import orderDetailsWithMerchantSlice from "../reducers/orderDetailsWithMerchantSlice";
import storeLocationWithMerchantSlice from "../reducers/storeLocationWithMerchantSlice";
import locationDetailWithMerchantSlice from "../reducers/locationDetailWithMerchantSlice";
import merchantsDetailSlice from "../reducers/merchantsDetailSlice";
import skusDetailSlice from "../reducers/skusDetailSlice";
import businessRulesMoneyOrdersSkusSlice from "../reducers/businessRulesMoneyOrdersSkusSlice";
import businessRulesMoneyOrdersSlice from "../reducers/businessRulesMoneyOrdersSlice";
import denominationsSlice from "../reducers/denominationsSlice";
import storeLocationBusinessRulesDetailSlice from "../reducers/storeLocationBusinessRules";
import locationServiceDetailSlice from "../reducers/locationServicesSlice";
import businessRulesTransportMoneyOrderSlice from "../reducers/businessRulesTransportMoneyOrderSlice";
import b2cUserSlice from "../reducers/b2cUserSlice";


export const store = configureStore({
  reducer: {
    userSlice,
    b2cUserSlice,
    authSlice,
    dashboardSlice,
    supportCaseSlice,
    orderDetailsSlice,
    storeLocationSlice,
    locationDetailSlice,
    registerSealbagSlice,
    transactionDetailSlice,
    supportCaseWithMerchantSlice,
    orderDetailsWithMerchantSlice,
    storeLocationWithMerchantSlice,
    locationDetailWithMerchantSlice,
    merchantsDetailSlice,
    skusDetailSlice,
    businessRulesMoneyOrdersSkusSlice,
    businessRulesMoneyOrdersSlice,
    denominationsSlice,
    storeLocationBusinessRulesDetailSlice,
    locationServiceDetailSlice,
    businessRulesTransportMoneyOrderSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

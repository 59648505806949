import { useTranslation } from "react-i18next";
import { Box, Flex, Label, Heading, Input } from "theme-ui";
import Select from "react-select";
import ErroroLabel from "./ErrorLabel";
import { PhoneType } from "@brinks/common/constants/PhoneType";
import { UserStatus } from "@brinks/common/constants/userStatus";
import { IS_AZURE_B2C_SCHEME } from "@brinks/common/utils/Config";
import { InputDisabledStyle } from "../../../helpers/styles";

interface Props {
  state: any;
  handleState: (key: string, value: string) => void;
  handleTouch: (key: string, value: string) => void;
}
export default function UserDetails({
  state,
  handleState,
  handleTouch,
}: Props) {
  const { t } = useTranslation();

  const option = [
    { value: PhoneType.MOBILE, label: "AddNewUser.mobile_phone_number" },
    { value: PhoneType.LAND_LINE, label: "AddNewUser.landline_phone_number" },
  ];
  //Azure B2C Integration starts here
  const userStatusOption = [
    { value: UserStatus.ACTIVE, label: "AddNewUser.status_active" },
    { value: UserStatus.INACTIVE, label: "AddNewUser.status_inactive" },
  ];

  //Azure B2C Integration ends here

  return (
    <>
      <Heading
        sx={{
          fontSize: "medium",
        }}
      >
        {t("AddNewUser.user_details")}
      </Heading>
      <Flex
        bg="white"
        sx={{
          p: 35,
          mt: 35,
          borderBottom: "1px solid",
          borderColor: "borderColor",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: 8,
          flexDirection: "column",
        }}
        data-testid="LocationList"
      >
        <Flex>
          <Box sx={{ width: 455 }}>
            <Label mb={3}>{t("AddNewUser.firstname")}</Label>
            <Input
              name="firstname"
              value={state.fields.firstname}
              placeholder={t("AddNewUser.firstname")}
              onBlur={(e) => {
                handleTouch(e.target.name, e.target.value);
              }}
              onChange={(e) => handleState(e.target.name, e.target.value)}
            />
            {state.errors.firstname && state.touched.firstname ? (
              <ErroroLabel message={t("AddNewUser.firstname_validation_msg")} />
            ) : (
              ""
            )}
          </Box>
          <Box sx={{ width: 455 }} ml={24}>
            <Label mb={3}>{t("AddNewUser.lastname")}</Label>
            <Input
              name="lastname"
              placeholder={t("AddNewUser.lastname")}
              onBlur={(e) => {
                handleTouch(e.target.name, e.target.value);
              }}
              value={state.fields.lastname}
              onChange={(e) => handleState(e.target.name, e.target.value)}
            />
            {state.errors.lastname && state.touched.lastname ? (
              <ErroroLabel message={t("AddNewUser.lastname_validation_msg")} />
            ) : (
              ""
            )}
          </Box>
        </Flex>
        <Box sx={{ width: 455 }} mt={35}>
          <Label mb={3}>{t("AddNewUser.email")}</Label>
          {
            //Azure B2C Integration starts here
            IS_AZURE_B2C_SCHEME && state.fields.userId !== undefined || null ?
              <div style={{ ...InputDisabledStyle }}>
                {state.fields.email}
              </div>
              //Azure B2C Integration ends here

              :
              <Input
                type="email"
                name="email"
                placeholder={t("AddNewUser.email")}
                onBlur={(e) => handleTouch(e.target.name, e.target.value)}
                value={state.fields.email}
                onChange={(e) => handleState(e.target.name, e.target.value)}
              />
          }
          {state.errors.email && state.touched.email ? (
            <ErroroLabel message={t("AddNewUser.email_validation_msg")} />
          ) : (
            ""
          )}
        </Box>
        <Flex>
          <Box sx={{ width: 455 }} mt={35}>
            <Label mb={3}>{t("AddNewUser.mobileNumber")}</Label>
            <Input
              name="mobileNo"
              placeholder={t("AddNewUser.mobileNumber")}
              onBlur={(e) => handleTouch(e.target.name, e.target.value)}
              value={state.fields.mobileNo}
              onChange={(e) => handleState(e.target.name, e.target.value)}
            />
            {state.errors.mobileNo && state.touched.mobileNo ? (
              <ErroroLabel message={t("AddNewUser.validation_msg")} />
            ) : (
              ""
            )}
          </Box>
          <Box sx={{ width: 455 }} ml={24} mt={35}>
            <Label mb={3}>{t("AddNewUser.type")}</Label>
            <Select
              className="basic-single"
              defaultValue={state.formIsValid ? state.fields.phoneType == "MOBILE" ? option[0] : option[1] : option[0]}
              classNamePrefix="select"
              name="phoneType"
              getOptionLabel={option => t(`${option.label}`)}
              options={option}
              onChange={(e: any) => {
                handleState("phoneType", e?.value);
              }}
            />
          </Box>
        </Flex>
        {
          //Azure B2C Integration starts here
          IS_AZURE_B2C_SCHEME && state.fields.userId !== undefined &&
          <Box sx={{ width: 455 }} mt={35}>
            <Label mb={3}>{t("AddNewUser.status")}</Label>
            <Select
              className="basic-single"
              defaultValue={state.formIsValid ? state.fields.status == "ACTIVE" ? userStatusOption[0] : userStatusOption[1] : userStatusOption[0]}
              classNamePrefix="select"
              name="status"
              getOptionLabel={userStatusOption => t(`${userStatusOption.label}`)}
              options={userStatusOption}
              onChange={(e: any) => {
                handleState("status", e?.value);
              }}
            />
          </Box>
          //Azure B2C Integration ends here
        }

      </Flex>
    </>
  );
}

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Api, Denomination, PreAnnouncement, CashDevice } from "../api/Api";

// Define a type for the slice state
interface registerSealbagSliceState {
  id: string;
  loading: boolean;
  loadingSubmitSealBag: boolean;
  selectedLocation: any;
  selectedCashDevice: CashDevice | null;
  denominations: Denomination[] | null;
  bagNumber: string;
  reviewDenominations: [] | null;
  declaredValues: any | null;
  preAnnouncementsResponse: PreAnnouncement[] | null;
  preAnnouncementsResponseError: string;
  grandTotal: number;
  totalWithCurrency: String;
  isPreAnnouncementError: boolean;
}

// Define the initial state using that type
const initialState: registerSealbagSliceState = {
  id: "",
  loading: false,
  loadingSubmitSealBag: false,
  denominations: null,
  bagNumber: "",
  selectedLocation: "",
  selectedCashDevice: null,
  reviewDenominations: [],
  declaredValues: null,
  preAnnouncementsResponse: [],
  grandTotal: 0,
  totalWithCurrency: "0",
  preAnnouncementsResponseError: "",
  isPreAnnouncementError: false,
};

export const getDenominations = createAsyncThunk<any, any, {}>(
  "denominations",
  async (data, thunkAPI) => {
    try {
      const res = await new Api().denominations.getDenominations(data);
      return res;
    } catch (error) {
      console.error("Error in getDenominations:", error);
      throw error;
    }
  }
);

export const submitSealBag = createAsyncThunk<any, any, {}>(
  "announcements",
  async (data: any, { getState }) => {
    const state = getState() as any;
    const res = await new Api().preAnnouncements.savePreAnnouncements(data);
    const currentState =
      state.registerSealbagSlice as registerSealbagSliceState;
    const preAnnouncementsRes = res.data.preAnnouncements;

    if (
      (`retailbox` ===
        currentState?.selectedCashDevice?.subType?.toLowerCase() ||
        `cit safe` ===
          currentState?.selectedCashDevice?.subType?.toLowerCase()) &&
      preAnnouncementsRes &&
      preAnnouncementsRes.length > 0
    ) {
      const preAnnoncementResponse = preAnnouncementsRes[0];

      const deposites = {
        deposits: [
          {
            ...data.preAnnouncements[0],
            status: `Created`,
            type: `Commercial`,
            preAnnouncementId: preAnnoncementResponse.id,
            sealbagId: preAnnoncementResponse.sealBag?.id,
            cashDeviceId: preAnnoncementResponse.sealBag?.cashDeviceId,
            currency:
              preAnnoncementResponse.declaredValues &&
              preAnnoncementResponse?.declaredValues[0]?.currency,
            transactionAmount: data.preAnnouncements[0].sealBag?.declaredTotal,
            transactionDateTime: new Date().toISOString(),
          },
        ],
      };
      const saveResponse = await new Api().deposits.saveDeposits(deposites);
    }
    return res;
  }
);

export const registerSealbagSlice = createSlice({
  name: "registerSealbagSlice",
  initialState,
  reducers: {
    setSealBagnumber: (state, action) => {
      state.bagNumber = action.payload;
    },
    setDenominations: (state, action) => {
      state.reviewDenominations = action.payload;
    },
    setGrandTotal: (state, action) => {
      state.grandTotal = action.payload;
    },
    setTotalWithCurrency: (state, action) => {
      state.totalWithCurrency = action.payload;
    },
    handleDeclaredQuantity: (state, action) => {
      state.declaredValues = action.payload;
    },
    setLocation: (state, action) => {
      state.selectedLocation = action.payload;
    },
    setCashDevice: (state, action) => {
      state.selectedCashDevice = action.payload;
    },
    setCashDeviceLoading: (state, action) => {
      state.loading = action.payload;
    },
    resetResponseError: (state) => {
      state.preAnnouncementsResponseError = "";
    },
    resetSealbagState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDenominations.pending, (state: registerSealbagSliceState) => {
        state.loading = true;
        state.denominations = null;
      })
      .addCase(
        getDenominations.fulfilled,
        (state: registerSealbagSliceState, { payload }: any) => {
          state.loading = false;
          state.denominations = payload.data.denominations;
        }
      )
      .addCase(
        getDenominations.rejected,
        (state: registerSealbagSliceState) => {
          state.loading = false;
        }
      )
      .addCase(submitSealBag.pending, (state: registerSealbagSliceState) => {
        state.loading = true;
        state.loadingSubmitSealBag = true;
        state.isPreAnnouncementError = false;
      })
      .addCase(
        submitSealBag.fulfilled,
        (state: registerSealbagSliceState, { payload }: any) => {
          state.loading = false;
          state.loadingSubmitSealBag = false;
          state.preAnnouncementsResponse = payload.data.preAnnouncements;
        }
      )
      .addCase(submitSealBag.rejected, (state: registerSealbagSliceState) => {
        state.loading = false;
        state.loadingSubmitSealBag = false;
        state.isPreAnnouncementError = true;
        state.preAnnouncementsResponseError = "Something went wrong";
      });
  },
});
export const {
  setSealBagnumber,
  setDenominations,
  setGrandTotal,
  handleDeclaredQuantity,
  resetSealbagState,
  setTotalWithCurrency,
  setLocation,
  setCashDevice,
  setCashDeviceLoading,
  resetResponseError,
} = registerSealbagSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default registerSealbagSlice.reducer;

import moment from "moment-timezone";
import TagManager from "react-gtm-module";
import { CellProps } from "react-table";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Table } from "../../ReactTable/ReactTable";
import { useEffect, useState, useMemo, useCallback } from "react";
import { Box, Flex, Button, Heading, Alert, Close } from "theme-ui";
import Loader from "../../../Shared/Loader";
import PageHeader from "../../../Shared/PageHeader";
import Wrapper from "../../../Shared/Wrapper/Wrapper";
import GlobalSearch from "../../Users/AddNewUser/GlobalSearch";
import { formatCurrency} from "@brinks/common/utils";
import { useAppDispatch, useAppSelector } from "@brinks/common/hooks/hooks";
import { OrderPickupIcon } from "@brinks/common/Icons/Components/order_pickup";
import { OrderChangeIcon } from "@brinks/common/Icons/Components/order_change";
import { OrderListMerchantSelector } from "./components/OrderListMerchantSelector";
import {
  updateOrder,
  getOrders,
  resetOrderUpdateResponse,
} from "@brinks/common/reducers/orderDetailsSlice";
import { OrderWithMerchant } from "@brinks/common/api/Api";
import { getCurrentUser } from "../../../utils/secure-storage-utils";
import ConfirmationModal from "../ConfirmationModal";
import SuccessModal from "./components/SuccessModal";
import { BackIcon } from "@brinks/common/Icons/Components/back";
import useGetUserRole from "../../../Hooks/useGetUserRole";
export const OrderList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [globalFilter, setGlobalFilter] = useState("");
  const [merchantFilter, setMerchantFilter] = useState<null | string>(null);
  const [orderId, setOrderId] = useState("");

  const user = getCurrentUser();
  // TESTE USER
  //Azure B2C Integration starts here
  const userType: string = useGetUserRole(true);
  //Azure B2C Integration ends here

  const [isConfirmationModalOpen, toggleConfirmationModal] = useState(false);
  const [isSuccessModalOpen, toggleSuccessModal] = useState(false);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        Page_title: "Overview orders | Overview",
      },
    });
    dispatch(getOrders({ userType: userType }));
  }, []);

  const { orders, loadingOrder, loadingUpdateOrder, orderUpdateResponse } =
    useAppSelector((state) => state.orderDetailsSlice);

  const dataOriginID = (e: any, type: string, showDeleteIcon: boolean) => {
    setOrderId(e.id);
    if (type === "click") {
      navigate(`/orders/details/${e.id}`, {
        state: { showDeleteIcon },
      });
    } else if (type === "delete") {
      toggleConfirmationModal((prevState) => !prevState);
    }
    dispatch(resetOrderUpdateResponse(""));
  };

  const submitCase = async () => {
    dispatch(updateOrder(orderId));
  };
  useEffect(() => {
    if (orderUpdateResponse === "Success") {
      toggleSuccessModal(true);
    }
    toggleConfirmationModal(false);
  }, [orderUpdateResponse]);

  const columns: any[] = useMemo( // a type of any[] is used here to avoid a TS error
    () => [
      {
        id: "type",
        disableFilters: true,
        Header: () => {
          return t("AddNewUser.type");
        },
        Cell: ({ row: { original } }: CellProps<OrderWithMerchant>) => {
          if (original && original.service) {
            const serviceName = original.service.name;
            return serviceName === "TRANSPORT_ORDER" ? (
              <OrderPickupIcon
                color={"order_primary"}
                secondaryColor={"order_secondary"}
              />
            ) : (
              <OrderChangeIcon
                color={"order_secondary"}
                secondaryColor={"order_primary"}
              />
            );
          }
        },
      },
      {
        id: "storeName",
        accessor: "storeName",
        disableFilters: true,
        Header: () => t("support.location_name"),
      },
      {
        disableFilters: true,
        id: "storeLocationCode",
        accessor: "storeLocationCode",
        Header: () => t("ManageLocations.location_code"),
      },
      {
        disableFilters: true,
        id: "deliveryDateTime",
        Header: () => t("ManageOrders.delivery_date"),
        accessor: (d: any) => moment(d.deliveryDateTime).format("DD/MM/YYYY"),
      },
      {
        id: "endDate",
        disableFilters: true,
        Header: () => t("ManageOrders.end_date"),
        accessor: (d: any) =>
          d.endDate && moment(d.endDate).format("DD/MM/YYYY"),
      },
      {
        id: "totalAmount",
        disableFilters: true,
        Header: () => t("ManageSealBags.amount"),
        accessor: (d: any) =>
          formatCurrency(
            d.totalAmount,
            d.currency ? d.currency : d.merchant.currency,
            d.merchant.country
          ),
      },
      {
        id: "status",
        accessor: "status",
        disableFilters: true,
        Header: () => t("support.status"),
      },
    ],
    []
  );

  const ordersFiltered = useMemo(() => {
    if (!orders || orders.length === 0) return [];

    return merchantFilter
      ? orders.filter(({ merchantId }) => merchantId === merchantFilter)
      : orders;
  }, [orders, merchantFilter]);

  if (loadingOrder) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  return (
    <>
      <PageHeader>
        <Heading>
          <button
            onClick={(e: any) => window.history.back()}
            style={{ marginRight: "20px", background: "transparent", border: "none", cursor: "pointer" }}
          >
            <BackIcon color={"black"} />
          </button>
          {t("ManageOrders.order_overview")}
        </Heading>
      </PageHeader>
      <Wrapper>
        <Flex mb={18} sx={{ flexWrap: 'wrap', justifyContent: "end", gap: 2, alignItems: "center" }}>
          <Button
            variant="muted"
            data-testid="register-sealbag-step1-next-btn"
            sx={{
              padding: 0,
              borderRadius: 0,
              cursor: "pointer",
              background: "none",
            }}
            onClick={() => {
              navigate(`/orders/change`);
              TagManager.dataLayer({
                dataLayer: {
                  event: "Click_event",
                  Action: "Order Change",
                  Page_title: "Order management | Start screen",
                },
              });
            }}
          >
            <OrderChangeIcon
              color={"order_secondary"}
              secondaryColor={"order_primary"}
            />
          </Button>
          <Button
            variant="muted"
            data-testid="register-sealbag-step1-next-btn"
            sx={{
              padding: 0,
              borderRadius: 0,
              cursor: "pointer",
              background: "none",
            }}
            onClick={() => {
              navigate(`/orders/pickup`);

              TagManager.dataLayer({
                dataLayer: {
                  event: "Click_event",
                  Action: "Order Pickup",
                  Page_title: "Order management | Start screen",
                },
              });
            }}
          >
            <OrderPickupIcon
              color={"order_primary"}
              secondaryColor={"order_secondary"}
            />
          </Button>
          <OrderListMerchantSelector
            orders={orders || []}
            onChange={(value) => setMerchantFilter(value)}
          />
          <GlobalSearch
            placeholder={t("ManageOrders.search_order")}
            searchLocation={(value) => setGlobalFilter(value)}
          />
        </Flex>
        <Flex
          bg="white"
          data-testid="OrderList"
          sx={{
            borderRadius: 8,
            flexDirection: "column",
            borderBottom: "1px solid #E2E5E9",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Table
            columns={columns}
            data={ordersFiltered}
            globalFilter={globalFilter}
            dataOriginID={(
              e: any,
              type: string = "",
              showDeleteIcon: boolean = false
            ) => dataOriginID(e, type, showDeleteIcon)}
          />
        </Flex>
      </Wrapper>
      <ConfirmationModal
        toggleConfirmationModal={toggleConfirmationModal}
        isConfirmationModalOpen={isConfirmationModalOpen}
        submit={submitCase}
        confirmationMsg={t("ManageOrders.please_confirm_cancel_order")}
        btnText={t("ManageOrders.cancel_order")}
      />
      <SuccessModal
        isSuccessModalOpen={isSuccessModalOpen}
        toggleSuccessModal={toggleSuccessModal}
      />
      {orderUpdateResponse && orderUpdateResponse === "Error" && (
        <Alert
          data-testid="error-alert"
          sx={{
            position: "fixed",
            left: "50%",
            top: "13%",
            width: 496,
            boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.1)",
            borderRadius: 4,
            fontSize: "subText",
            fontWeight: "weight_400",
          }}
        >
          {t("ManageOrders.order_cant_be_canceled")}
          <Close
            ml="auto"
            sx={{
              cursor: "pointer",
            }}
            mr={-2}
            onClick={() => dispatch(resetOrderUpdateResponse(""))}
            data-testid="error-alert-close-icon"
          />
        </Alert>
      )}
    </>
  );
};

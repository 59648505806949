/** @jsxImportSource theme-ui */

import { useAppDispatch, useAppSelector } from "@brinks/common/hooks/hooks";
import React, { useEffect, useState } from "react";
import { verifyOTP, sendOtp, resetVerifyOTP } from "@brinks/common/reducers/authSlice";
import {
    Flex,
    Label,
    Text,
    Alert,
    Input,
    Button,
    Heading,
    Card,
    Close,
} from "theme-ui";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReSendIcon } from "@brinks/common/Icons/Components/resendIcon";
import { ErrorIcon } from "@brinks/common/Icons/Components/error";
import { useTranslation } from "react-i18next";
import Logo from "@brinks/common/Icons/Logo";
import LanguageSelector from "./LanguageSelector";
import pkceChallenge from "pkce-challenge";

import {
    REACT_APP_NATIVE_APP_CLIENT_ID,
    REACT_APP_NATIVE_APP_REDIRECT_URI,
    REACT_APP_NATIVE_APP_DISCOVERY_URI,
    REACT_APP_NATIVE_APP_BASE_URL
} from "@brinks/common/utils/Config";
import TagManager from "react-gtm-module";
import config from "@brinks/common/config";
import useTheme from "../../Hooks/useTheme";
interface Props {
    stateTokenResp: any;
    showSuccess: () => void
}
function ResetPasswordOtp({ stateTokenResp, showSuccess }: Props) {

    useEffect(() => {
        TagManager.dataLayer({
            dataLayer: {
                event: "page_view",
                Page_title: "User registration | Enter verification code",
            }
        });
    }, []);

    const [securityCode, setSecurityCode] = useState("");
    const [isTokenError, setIsTokenError] = useState(false);
    const [verifyOTPData, setVerifyOTPData] = useState<any>();
    const [isSmsError, setIsSmsError] = useState(false);
    const [counter, setCounter] = React.useState(60);
    const { t } = useTranslation();
    const themeType = useTheme();

    const [loading, setLoading] = useState(false)
    const [isEmpty, setIsEmpty] = useState(false);




    useEffect(() => {
        const timer: any =
            counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    const resendCode = () => {
        setCounter(60);
        const stateToken: any = localStorage.getItem("stateToken");
        const tokenData: any = JSON.parse(stateToken)
        sendOtp(tokenData);
    };




    const handleVerifySms = async () => {
        if (!securityCode) {
            setIsEmpty(true)
            return;
        }
        setIsEmpty(false)
        setLoading(true)
        const stateToken: any = localStorage.getItem("stateToken");
        const tokenData: any = JSON.parse(stateToken)
        try {

            const url: any = `${REACT_APP_NATIVE_APP_BASE_URL}/api/v1/authn/factors/${tokenData._embedded.factors[0].id}/verify`;
            const res = await fetch(url, {
                method: "POST",
                body: JSON.stringify({
                    stateToken: tokenData?.stateToken,
                    passCode: securityCode,
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            });
            const resp = await res.json();
            setLoading(false)
            if (resp?.errorId) {
                setIsSmsError(true);
                return
            }
            if (resp.status === "SUCCESS") {
                showSuccess()
            }
        } catch (error) {
            setIsSmsError(true);
            setLoading(false)


        }





    };

    return (


        <>

            <Flex sx={{ display: ["block", "none"], mt: 50 }}>
                <Logo themeType={themeType} />
            </Flex>


            <Card
                data-testid="OtpPage-card"
                sx={{
                    width: ["100%", "50%"],
                    px: ["16px", 0],
                    mt: [50, 200],
                }}
            >
                <Flex
                    data-testid="OtpPage-card-wrapper"
                    mt={25}
                    sx={{
                        flexDirection: "column",
                    }}
                >
                    <Heading data-testid="OtpPage-card-heading">
                        {t("Registration.enter_verification")}
                    </Heading>
                    <Text
                        data-testid="OtpPage-card-sms-text"
                        mt={25}
                        sx={{
                            fontWeight: "weight_400",
                            color: "shade_600",
                        }}
                    >
                        {t("Registration.sms")}
                    </Text>
                    <Label
                        data-testid="OtpPage-card-label"
                        mt={25}
                        sx={{
                            fontSize: "subText",
                            color: "shade_800",
                        }}
                    >
                        {t("Registration.code_lable")}
                    </Label>
                    <Input
                        data-testid="OtpPage-card-input"
                        sx={{
                            p: "17px",
                            border: "1px solid",
                            borderColor: "borderColor",
                            borderRadius: "6px",
                        }}
                        mt={25}
                        type="number"
                        autoFocus={true}
                        value={securityCode}
                        onKeyDown={(e: any) => {
                            if (e.key === "Enter") {
                                TagManager.dataLayer({
                                    dataLayer: {
                                        event: "Click_event",
                                        Page_title: "User registration | Enter verification code",
                                        Action: "Confirm verification code"
                                    }
                                });
                                handleVerifySms();
                            }
                        }}
                        onChange={(e) => {
                            setSecurityCode(e.target.value);
                        }}
                        placeholder={t("Registration.enter_code")}
                        required
                    />
                    {isEmpty && (
                        <Flex pt={2} data-testid="activation-card-error-flex">
                            <ErrorIcon
                                color={"raspberry_500"}
                                data-testid="activation-card-error-icon"
                            />
                            <Text
                                data-testid="activation-card-invalid-code"
                                pl={2}
                                color="raspberry_500"
                                sx={{
                                    fontSize: "subText",
                                    fontWeight: "weight_400",
                                }}
                            >
                                {t("Registration.enter_otp")}
                            </Text>
                        </Flex>
                    )}
                    {isSmsError && (
                        <Flex pt={2} data-testid="activation-card-error-flex">
                            <ErrorIcon
                                color={"raspberry_500"}
                                data-testid="activation-card-error-icon"
                            />
                            <Text
                                data-testid="activation-card-invalid-code"
                                pl={2}
                                color="raspberry_500"
                                sx={{
                                    fontSize: "subText",
                                    fontWeight: "weight_400",
                                }}
                            >
                                {t("Registration.code_invalid")}
                            </Text>
                        </Flex>
                    )}





                    <Flex
                        data-testid="OtpPage-card-resend-code-flex"
                        mt={25}
                        sx={{
                            justifyContent: "space-between",
                            alignItems: ["flex-start", "center"],
                            flexDirection: ["column-reverse", "row"],
                        }}
                    >
                        {counter === 0 ? (
                            <Flex
                                onClick={() => {
                                    TagManager.dataLayer({
                                        dataLayer: {
                                            event: "Click_event",
                                            Page_title: "User registration | Enter verification code",
                                            Action: "Resend verification code"
                                        }
                                    });
                                    resendCode();
                                }}
                                sx={{ alignItems: "center", cursor: "pointer" }}
                            >
                                <ReSendIcon color={"primary"} />
                                <Text
                                    ml={2}
                                    color="royalBlue_500"
                                    sx={{
                                        fontWeight: "weight_400",
                                        lineHeight: "27.2px",
                                        textAlign: "center",
                                        cursor: "pointer",
                                    }}
                                >
                                    {t("Registration.resend_code")}
                                </Text>
                            </Flex>
                        ) : (
                            <Text
                                data-testid="OtpPage-card-resend-code-flex-text"
                                color="shade_400"
                            >
                                {t("Registration.resend_code_in")} {counter}
                            </Text>
                        )}
                        <Button
                            data-testid="OtpPage-card-resend-code-flex-button"
                            onClick={handleVerifySms}
                            variant={`${loading ? "gray" : "primary"}`}
                            sx={{
                                boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
                                height: 49,
                                width: ["100%", "169px"],
                                mb: [20, 0],
                                borderRadius: "40px",
                                cursor: "pointer",
                            }}
                        >
                            {t("confirm")}
                        </Button>
                    </Flex>
                </Flex>
            </Card>
        </>

    );
}

export default ResetPasswordOtp;

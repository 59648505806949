const env = typeof window != "undefined" && window.document ? "WEB" : "MOBILE";
let AsyncStorage = null;
import jwt_decode from "jwt-decode";
import { oktaAuthGlobal, timer } from "../utils";
import { msalAuthGlobal } from "../utils";
import { AuthenticationResult, EventMessage, EventType, InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";
import { IS_AZURE_B2C_SCHEME, MSAL_SCOPE } from "./Config";

let oktaRN = null;
if (env === "MOBILE") {
  AsyncStorage = require(`react-native`).AsyncStorage;
  oktaRN = require("@okta/okta-react-native");
}

export const StorageUtil = {
  getItem: async (key: any) => {
    if (env === "MOBILE") {
      if (`access_token` === key) {
        const isAuthenticatedValue = await oktaRN.isAuthenticated();
        if (!isAuthenticatedValue.isAuthenticated) {
          await oktaRN.refreshTokens();
        }
        const tkn = await oktaRN.getAccessToken();
        return tkn.access_token;
      } else {
        return await AsyncStorage.getItem(key);
      }
    }
    if (env === "WEB") {
      //Azure B2C Integration starts here
      //validating the token
      let accessToken;
      let isTokenValid;
      if (IS_AZURE_B2C_SCHEME) {
        if (localStorage.getItem('msal-access-token') === null) {            
          acquireAccessToken(msalAuthGlobal);
        } else {       
          isTokenValid = validateToken(true);
        }
        accessToken = localStorage.getItem('msal-access-token');
      }
      //Azure B2C Integration ends here
      else {
        isTokenValid = validateToken(false);
        while (oktaAuthGlobal.getAccessToken() && !isTokenValid) {
          await timer(200);
          isTokenValid = validateToken(false);
        }
        accessToken = oktaAuthGlobal.getAccessToken();
      }
      return accessToken
    }

    //Azure B2C Integration starts here
    // get access token form b2c using MSAL and store in lcoal storage to access in the app
    function acquireAccessToken(msalInstance: PublicClientApplication) {
      const activeAccount = msalInstance.getActiveAccount();
      const accounts = msalInstance.getAllAccounts();

      msalInstance.addEventCallback((event: EventMessage) => {
        const eventData = event.payload as AuthenticationResult;
      
      if (event.eventType === EventType.LOGIN_SUCCESS) {
          msalInstance.setActiveAccount(eventData.account);
          localStorage.setItem('msal-access-token',eventData.accessToken);
        }
      });

      if (!activeAccount && accounts.length === 0) {
        msalInstance.loginRedirect({
          scopes:[MSAL_SCOPE]
        }).catch((err: any) => {
          console.error(err);
        });;
      }
      const request = {
        scopes:[MSAL_SCOPE],
        account: activeAccount || accounts[0],
        forceRefresh: false
      };

      msalInstance.acquireTokenSilent(request)
        .then((authResult: AuthenticationResult) => {
          localStorage.setItem('msal-access-token', authResult.accessToken);
          msalInstance.setActiveAccount(authResult.account);
        })
        .catch((error: any) => {
          if (error instanceof InteractionRequiredAuthError) {
            return msalInstance.acquireTokenRedirect(request)
          }
        });
    };
    //Azure B2C Integration ends here

    function validateToken(isB2C: boolean) {
      try {        
        const decoded: any = jwt_decode(isB2C ? localStorage.getItem('msal-access-token') : oktaAuthGlobal.getAccessToken());
        if (decoded && decoded.exp && Date.now() / 1000 < decoded.exp) {
          return true;
        } else {
          if(isB2C) {
            acquireAccessToken(msalAuthGlobal);
          } else {
            throw new Error('Token is invalid or has expired');
          }
        }
      } catch (e) {
        console.log('Error validating token:', e);
        throw new Error('Token validation failed');
      }
    }
  },
  setItem: (key: any, value: any) => {
    if (env === "MOBILE")
      return AsyncStorage.setItem(key, JSON.stringify(value));
    return localStorage.setItem(key, JSON.stringify(value));
  },
};

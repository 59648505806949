import "./index.scss";
import React, { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "@brinks/common/hooks/hooks";
import {
  setSelectedPeriodFilter,
  getTransactions,
  PeriodFilterType,
} from "@brinks/common/reducers/dashboardSlice";
import { getDateByDays, getCurrentDate } from "@brinks/common/utils";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import useGetUserRole from "../../Hooks/useGetUserRole";

export default function Fitertab() {
  
  //Azure B2C Integration starts here
  const userRole: string = useGetUserRole(true);
  //Azure B2C Integration ends here
 
  const [timeFilter, setTimeFilter] = React.useState(7);
  const reduxState = useAppSelector((state) => state.dashboardSlice);
  const { selectedQuickTimeFilter } = reduxState;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const handleFilter = (days: number): void => {
    setTimeFilter(days);
    const periodFilter: PeriodFilterType = {
      selectedPeriodStartDate: getDateByDays(days),
      selectedPeriodEndDate: getCurrentDate(),
    };
    dispatch(setSelectedPeriodFilter(periodFilter));
    dispatch(getTransactions({ ...reduxState, ...periodFilter, userType: userRole }));
  };

  useEffect(() => {
    handleFilter(selectedQuickTimeFilter);
  }, []);

  return (
    <>
      <span
        data-testid="filter-7"
        className={`filters-value ${selectedQuickTimeFilter === 7 ? "active" : ""
          }`}
        onClick={() => {
          TagManager.dataLayer({
            dataLayer: {
              event: "Click_event",
              Page_title: "Dashboard | Overview - Filter",
              Action: "7 Days"
            }
          });
          handleFilter(7);
        }}
      >
        7 {t("Dashboard.days")}
      </span>
      <span
        data-testid="filter-30"
        className={`filters-value ${selectedQuickTimeFilter === 30 ? "active" : ""
          }`}
        onClick={() => {
          TagManager.dataLayer({
            dataLayer: {
              event: "Click_event",
              Page_title: "Dashboard | Overview - Filter",
              Action: "30 Days"
            }
          });
          handleFilter(30);
        }}
      >
        30 {t("Dashboard.days")}
      </span>
      <span
        data-testid="filter-90"
        className={`filters-value ${selectedQuickTimeFilter === 90 ? "active" : ""
          }`}
        onClick={() => {
          TagManager.dataLayer({
            dataLayer: {
              event: "Click_event",
              Page_title: "Dashboard | Overview - Filter",
              Action: "3 months"
            }
          });
          handleFilter(90);
        }}
      >
        3 {t("Dashboard.months")}
      </span>
    </>
  );
}

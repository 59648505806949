/** @jsxImportSource theme-ui */

import React, { useEffect, useState } from "react";

import { Provider } from "react-redux";
import { store } from "@brinks/common/store";
import { oktaAuthGlobal } from '@brinks/common/utils'
import Dashboard from "./components/Dashboard/Dashboard";
import Setting from "./components/Setting/Setting";

import { Box, Flex, ThemeProvider, useColorMode } from "theme-ui";
import { theme } from "./theme";
import Layout from "./components/Layout";
import {
  BrowserRouter as Router,
  Routes,
  useNavigate,
  Route,
} from "react-router-dom";
import TransactionDetail from "./components/TransactionDetail";
import { LocationList } from "./components/ManageLocations/LocationsList";
import { LocationDetailsPage } from "./components/ManageLocations/LocationDetails";
import Activation from "./components/Registration/Activation";
import SetPassword from "./components/Registration/SetPassword";
import Login from "./components/Registration/Login";
import Logout from "./components/Registration/Logout";
import Callback from "./components/Registration/Login/Callback";
import RegisterSealBag from "./components/ManageSealbags/RegisterSealbags";
import { AddNewUser } from "./components/Users/AddNewUser";
import { Users } from "./components/Users/Users";
import UserAccountDetails from "./components/Users/UserAccount";
import Orders from "./components/ManageOrders";
import { OrderChange } from "./components/ManageOrders/OrderChange";
import OrderPickup from "./components/ManageOrders/OrderPickup";
import { OrderList } from "./components/ManageOrders/OrderList";
import { OrderDetails } from "./components/ManageOrders/OrderList/OrderDetail";
import TagManager from "react-gtm-module";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import AuthRequiredModal from "./OktaService/AuthRequiredModal";
import CorsErrorModal from "./OktaService/CorsErrorModal";
import { IS_AZURE_B2C_SCHEME, MSAL_SCOPE, REACT_APP_WEB_GTM_ID, REACT_APP_WEB_THEME } from "@brinks/common/utils/Config";
import brinksfavicon from "@brinks/common/Icons/brinks.png";
import bluebeem from "@brinks/common/Icons/favicon.png";
import ForgotPassword from "./components/Registration/ForgotPassword";
import Support from "./components/Support";
import { CreateCase } from "./components/Support/SupportCreate";
import { SupportList } from "./components/Support/SupportList";
import SupportDetail from "./components/Support/SupportList/SupportDetails";
import DeviceManagement from "./components/DeviceManagement";
import ResetPassword from "./components/Registration/ResetPassword";

import { MsalProvider, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import Loader from "./Shared/Loader";

const tagManagerArgs = {
  gtmId: `${REACT_APP_WEB_GTM_ID}`,
};

TagManager.initialize(tagManagerArgs);

const NotFound = () => {
  return (
    <Flex
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      404 Not found
    </Flex>
  );
};
const oktaAuth = oktaAuthGlobal;
function App({ msalInstance }: any) {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        {/* //Azure B2C Integration starts here */}
        {
          IS_AZURE_B2C_SCHEME ?
            <MsalProvider instance={msalInstance}>
              <Router >
                <ComponentsRoutes />
              </Router>
            </MsalProvider>
            :
            <Router >
              <ComponentsRoutes />
            </Router>
        }
        {/* //Azure B2C Integration ends here */}

      </ThemeProvider>

    </Provider>
  );
}
function getFaviconEl() {
  return document.getElementById("favicon");
}
const ComponentsRoutes = () => {
  const [corsErrorModalOpen, setCorsErrorModalOpen] = React.useState(false);

  const [authRequiredModalOpen, setAuthRequiredModalOpen] =
    React.useState(false);

  const navigate = useNavigate();

  const [colorMode, setColorMode] = useColorMode();


  useEffect(() => {
    const favicon: any = getFaviconEl();
    if (REACT_APP_WEB_THEME === `brinks`) {
      // Accessing favicon element
      favicon.href = brinksfavicon;
      document.title = "Brinks"
      setColorMode("brinks");
    } else {

      favicon.href = bluebeem;
      setColorMode("default");
    }
  }, [])

  const triggerLogin = () => {
    // Redirect to the /login page that has a CustomLoginComponent
    navigate("/login");
  };

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin), {
      replace: true,
    });
  };
  //Azure B2C Integration starts here
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const azureAuthHandler = () => {
    /*
    This is for closing the popup window if the app is rendering inside it.
     */
    if (!window.menubar.visible) {
      window.close();
    }
    /*
    If the user is not authenticated and if there aren't any login process currently being carried out, then the user will be redirected to AzureB2C login page. 
    */
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      instance.loginRedirect({
        scopes: [MSAL_SCOPE],
      }).catch((err: any) => {
        console.error(err);
      });
    }
  }
  /*
   Checks if the env variable that specifies which Authentication Scheme to be used is set to AzureB2C, if set then boolean true will be returned else boolean false will be returned. 
   Based the boolean value, azureAuthHandler() will be called and continues with AzureB2C authentication process.
  */
  if (IS_AZURE_B2C_SCHEME) {
    azureAuthHandler();
  }

  if (!isAuthenticated && IS_AZURE_B2C_SCHEME) {
    return (
      <Box>
        <Loader customStyle={300} />
      </Box>
    );// Show a loading indicator while authentication is in progress
  }
  //Azure B2C Integration ends here

  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      // App initialization stage
      triggerLogin();
    } else {
      // Ask the user to trigger the login process during token autoRenew process
      setAuthRequiredModalOpen(true);
    }
  };

  const routes = <Routes>
    <React.Fragment>
      <>
        <Route path="/user-activation" element={<Activation />} />
        <Route path="/set-password" element={<SetPassword />} />
        <Route path="/login" element={<Login />} />
        <Route path="/callback" element={<Callback />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
      </>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/locations" element={<LocationList />} />
        <Route path="/locations/:id" element={<LocationDetailsPage />} />
        <Route path="/transaction/:id/:type" element={<TransactionDetail />} />
        <Route path="/users" element={<Users />} />
        <Route path="/users/:id" element={<UserAccountDetails />} />
        <Route path="/addnewuser" element={<AddNewUser />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/orders/change" element={<OrderChange />} />
        <Route path="/orders/pickup" element={<OrderPickup />} />
        <Route path="/orders/list" element={<OrderList />} />
        <Route path="/orders/details/:id" element={<OrderDetails />} />
        <Route path="/support" element={<Support />} />
        <Route path="/support/create" element={<CreateCase />} />
        <Route path="/support/list" element={<SupportList />} />
        <Route path="/support/details/:id" element={<SupportDetail />} />
        <Route path="/register-sealbag" element={<RegisterSealBag />} />
        <Route path="/settings" element={<Setting />} />
        <Route path="/device-management" element={<DeviceManagement />} />
        <Route path="/logout" element={<Logout />} />
      </Route>
      <Route path="/*" element={<NotFound />} />
    </React.Fragment>
  </Routes>;

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      <CorsErrorModal {...{ corsErrorModalOpen, setCorsErrorModalOpen }} />
      <AuthRequiredModal
        {...{
          authRequiredModalOpen,
          setAuthRequiredModalOpen,
          triggerLogin,
        }}
      />
      {routes}
    </Security>
  );
};

export default App;

/** @jsxImportSource theme-ui */

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Button, Heading, Card, Box } from "theme-ui";
import { InboxIcon } from "@brinks/common/Icons/Components/inboxicon";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
interface Props {
  inResetFlow: Boolean;
}
function SuccessPage({ inResetFlow }: Props) {
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        Page_title: "User registration | Registration succesful",
      },
    });
  }, []);
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Card
      data-testid="successPage"
      mt={200}
      sx={{
        width: "500px",
      }}
    >
      <Flex
        data-testid="successPage-wrapper"
        mt={25}
        sx={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Flex
          data-testid="successPage-icon-wrapper"
          sx={{
            width: 71,
            height: 71,
            borderRadius: "50%",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor:
              "linear-gradient(103.07deg, royalBlue_500 0%, secondary_300 100%)",
          }}
        >
          <InboxIcon color={"white"} data-testid="successPage-icon" />
        </Flex>
        <Heading
          data-testid="successPage-heading"
          mt={16}
          color="royalBlue_700"
          sx={{
            lineHeight: "32.23px",
            textAlign: "center",
          }}
        >
          {inResetFlow
            ? t("Registration.password_changed")
            : t("Registration.registration_success")}
        </Heading>
        {inResetFlow && (
          <Box
            sx={{
              fontWeight: "weight_400",
              color: "shade_400",
              mt: 24,
            }}
          >
            {t("Registration.password_changed_sub")}
          </Box>
        )}

        <Button
          data-testid="successPage-btn"
          mt={50}
          value="Submit"
          onClick={() => {
            TagManager.dataLayer({
              dataLayer: {
                event: "Click_event",
                Page_title: "User registration | Registration succesful",
                Action: "Continue to login",
              },
            });
            navigate(`/login`);
          }}
          sx={{
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
            height: 50,
            width: 264,
            borderRadius: "40px",
            cursor: "pointer",
          }}
        >
          {t("Registration.continue_login")}
        </Button>
      </Flex>
    </Card>
  );
}

export default SuccessPage;

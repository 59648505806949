import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppDispatch } from "@brinks/common/hooks/hooks";
import { reset } from "@brinks/common/reducers/authSlice";
import { useOktaAuth } from "@okta/okta-react";
import { useMsal } from "@azure/msal-react";
import { Box } from "theme-ui";
import Loader from "../../../Shared/Loader";
import { IS_AZURE_B2C_SCHEME } from "@brinks/common/utils/Config";

const Logout: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  //Azure B2C Integration starts here 
  const { instance } = useMsal();
  const { oktaAuth } = useOktaAuth();

  const msalLogOut = () => {
    localStorage.removeItem('msal-access-token');
    instance.logoutRedirect();
  }

  React.useEffect(() => {
    if (IS_AZURE_B2C_SCHEME) {
      msalLogOut();
    }
    //Azure B2C Integration ends here
    else {      
      localStorage.clear();
      oktaAuth.signOut();
      oktaAuth.revokeAccessToken();
      oktaAuth.revokeRefreshToken();
      oktaAuth.tokenManager.clear();
      dispatch(reset());
      window.history.replaceState(null, "null", "/login");
      window.location.reload()
    }
  }, []);

  if(IS_AZURE_B2C_SCHEME){
    return (
      <Box>
        <Loader customStyle={300} />
      </Box>
    );
  }
  else{
    return (
      <>
        <Navigate to="/login" replace state={{ path: location.pathname }} />
      </>
    );
  }

};

export default Logout;
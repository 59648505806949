import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  Api,
  GetMerchantsAndLocationsResponse,
  MerchantsAndLocations,
} from "../api/Api";

// Define the initial state using that type
const initialState: merchantsDetailSliceState = {
  loadingMerchants: false,
  merchants: null,
  currentMerchant: null,
};

interface merchantsDetailSliceState {
  loadingMerchants: boolean;
  merchants: MerchantsAndLocations[] | null;
  currentMerchant: MerchantsAndLocations | null;
}

export const getMerchants = createAsyncThunk<any, any, {}>(
  "MerchantsAndLocations",
  (data, thunkAPI) => {
    const res = new Api().merchants.getAllMerchants(data);
    return res;
  }
);

export const merchantsDetailSlice = createSlice({
  name: "merchantsDetailSlice",
  initialState,
  reducers: {
    setMerchants: (state, action) => {
      state.merchants = action.payload;
    },
    setCurrentMerchant: (state, action) => {
      state.currentMerchant = action.payload;
    },
    setLoadingMerchant: (state, action) => {
      state.loadingMerchants = action.payload;
    },
    resetWebFilter: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMerchants.pending, (state: merchantsDetailSliceState) => {
        (state.loadingMerchants = true), (state.merchants = null);
      })
      .addCase(
        getMerchants.fulfilled,
        (state: merchantsDetailSliceState, { payload }: any) => {
          (state.loadingMerchants = false),
            (state.merchants = payload.data.merchants);
        }
      )
      .addCase(getMerchants.rejected, (state: merchantsDetailSliceState) => {
        state.loadingMerchants = false;
      });
  },
});

export const {
  setMerchants,
  setCurrentMerchant,
  resetWebFilter,
  setLoadingMerchant,
} = merchantsDetailSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default merchantsDetailSlice.reducer;


import { Heading } from "theme-ui";

import SelectPickupLocation from "./SelectPickupLocation";
import { useState, useEffect } from "react";
import ReviewDeposite from "./ReviewPickupDetails";
import SelectTimeslotDetails from "./selectTimeslotDetails";
import Wrapper from "../../../Shared/Wrapper/Wrapper";
import PageHeader from "../../../Shared/PageHeader";
import { useTranslation } from "react-i18next";
import { BackIcon } from "@brinks/common/Icons/Components/back";
import { useNavigate } from "react-router-dom";
import TagManager from "react-gtm-module";
import { getStoreLocations } from "@brinks/common/reducers/storeLocationSlice";
import { useAppDispatch } from "@brinks/common/hooks/hooks";
import useGetUserRole from "../../../Hooks/useGetUserRole";

export default function RegisterSealbag() {
  const dispatch = useAppDispatch();
  //Azure B2C Integration starts here
  const userType: string = useGetUserRole(true);
  //Azure B2C Integration ends here

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        Page_title: "Order management | Order Pickup - Select location",
        Action: "Request Pickup"
      }
    })
  }, [])

  useEffect(() => {
    dispatch(getStoreLocations({ userType: userType }));
  }, []);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [step, updateStep] = useState(1);

  const nextStep = () => {
    updateStep(step + 1);
  };
  const previousStep = () => {
    if (step == 1) {
      navigate(`/orders`);
    } else {
      updateStep(step - 1);
    }
  };

  const getCurrentStep = () => {
    switch (step) {
      case 1:
        return <SelectPickupLocation nextStep={nextStep} />;
      case 2:
        return <SelectTimeslotDetails nextStep={nextStep} />;
      case 3:
        return (
          <ReviewDeposite updateStep={updateStep} previousStep={previousStep} tagType={"orderPickup"} />
        );
      default:
      // do nothing
    }
  };

  return (
    <>
      <PageHeader>
        <Heading>
          <button
            onClick={(e: any) => {
              TagManager.dataLayer({
                dataLayer: {
                  event: "Click_event",
                  Page_title: "Order management | Order Pickup - Select location",
                  Action: "Previous"
                }
              });
              previousStep();
            }}
            style={{ marginRight: "20px", background: "transparent", border: "none", cursor: "pointer" }}
          >
            <BackIcon color={"black"} />
          </button>
          {step === 3 ? t("Header.review_pickup") : t("Header.request_pickup")}
        </Heading>
      </PageHeader>
      <Wrapper>{getCurrentStep()}</Wrapper>
    </>
  );
}

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Api, BusinessRuleLocationServicesMoneyOrderSku } from "../api/Api";

// Define the initial state using that type
const initialState: businessRulesMoneyOrdersSkusDetailSliceState = {
  loadingBusinessRulesMoneyOrdersSkus: false,
  businessRulesMoneyOrdersSkus: null,
  currentBusinessRulesMoneyOrdersSku: null,
};

interface businessRulesMoneyOrdersSkusDetailSliceState {
  loadingBusinessRulesMoneyOrdersSkus: boolean;
  businessRulesMoneyOrdersSkus:
    | BusinessRuleLocationServicesMoneyOrderSku[]
    | null;
  currentBusinessRulesMoneyOrdersSku: BusinessRuleLocationServicesMoneyOrderSku | null;
}

export const getRulesMoneyOrderSkus = createAsyncThunk<any, any, {}>(
  "BusinessRuleLocationServicesMoneyOrderSkusResponse",
  (data, thunkAPI) => {
    const res = new Api().businessRules.getRulesMoneyOrderSkus(data);
    return res;
  }
);

export const businessRulesMoneyOrdersSkusSlice = createSlice({
  name: "businessRulesMoneyOrdersSkusSlice",
  initialState,
  reducers: {
    setBusinessRulesMoneyOrdersSkus: (state, action) => {
      state.businessRulesMoneyOrdersSkus = action.payload;
    },
    setCurrentBusinessRulesMoneyOrdersSkus: (state, action) => {
      state.currentBusinessRulesMoneyOrdersSku = action.payload;
    },
    setLoadingBusinessRulesMoneyOrdersSkus: (state, action) => {
      state.loadingBusinessRulesMoneyOrdersSkus = action.payload;
    },
    resetWebFilter: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRulesMoneyOrderSkus.pending, (state) => {
        state.loadingBusinessRulesMoneyOrdersSkus = true;
        state.businessRulesMoneyOrdersSkus = null;
      })
      .addCase(getRulesMoneyOrderSkus.fulfilled, (state, { payload }) => {
        state.loadingBusinessRulesMoneyOrdersSkus = false;
        state.businessRulesMoneyOrdersSkus = payload.data;
      })
      .addCase(getRulesMoneyOrderSkus.rejected, (state) => {
        state.loadingBusinessRulesMoneyOrdersSkus = false;
      });
  },
});

export const {
  setBusinessRulesMoneyOrdersSkus,
  setCurrentBusinessRulesMoneyOrdersSkus,
  resetWebFilter,
  setLoadingBusinessRulesMoneyOrdersSkus,
} = businessRulesMoneyOrdersSkusSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default businessRulesMoneyOrdersSkusSlice.reducer;

